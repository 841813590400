import {Injectable} from "@angular/core";
import {CdnStore} from "./cdn.store";
import {CdnQuery} from "./cdn.query";
import {HttpClient} from "@angular/common/http";
import {EventsQuery} from "../events/events.query";
import {catchError, map, switchMap, take} from "rxjs/operators";
import {Observable} from "rxjs";
import {EventsService} from "../events/events.service";
import {ContentCDNFile, ContentCDNType} from "../../core/models/content-cdn-file";
import {EventDaysQuery} from "../event-days/event-days.query";
import {combineQueries} from "@datorama/akita";
import {EventeeHttpClient} from '../../core/services/http/eventee.http-client';
import {CDNHttpClient} from "../../core/services/http/cdn.http-client";

@Injectable({
	providedIn: 'root'
})
export class CdnService {

	constructor(
		private cdnStore: CdnStore,
		private cdnQuery: CdnQuery,
		private eventsQuery: EventsQuery,
		private eventsService: EventsService,
		private eventDaysQuery: EventDaysQuery,
		private http: CDNHttpClient) {
	}

	getFromCDN<T>(type: ContentCDNType, request: Observable<T>): Observable<T> {
		return combineQueries([
			this.eventsService.getOne(this.eventsQuery.getActiveId()),
			this.cdnQuery.getContentCdnFile$(type)
		]).pipe(
			take(1),
			switchMap(([event, lastUsed]) => {
				const newOne = event.contentCdnFiles.find(cdnFile => cdnFile.type === type);
				return this.resolveResponse(lastUsed, newOne, request);
			})
		);
	}

	getFromCDNForDay<T>(eventDayId: string, request: Observable<T>): Observable<T> {
		const type = ContentCDNType.DAY_CONTENT + '-' + eventDayId;
		return combineQueries([
			this.eventDaysQuery.selectEntity(eventDayId),
			this.cdnQuery.getContentCdnFile$(type)
		]).pipe(
			take(1),
			switchMap(([eventDay, lastUsed]) => {
				const newOne = {
					type: type,
					contentUrl: eventDay.contentUrl
				} as ContentCDNFile
				return this.resolveResponse(lastUsed, newOne, request);
			})
		);
	}

	private resolveResponse<T>(lastUsed: ContentCDNFile, newOne: ContentCDNFile, request: Observable<T>): Observable<T> {
		if (!newOne?.contentUrl) {
			// CDN file does not exist
			return request;
		} else if (lastUsed?.contentUrl === newOne.contentUrl) {
			// already downloaded CDN file
			return this.cdnQuery.select(state => {
				return state.data[newOne.type];
			}).pipe(
				take(1)
			);
		} else {
			// downloading new CDN file
			return this.http.get(newOne.contentUrl).pipe(
				map((data: T) => {
					this.cdnStore.upsertMany([newOne]);
					this.cdnStore.updateData(newOne.type, data);
					return data;
				}),
				catchError(() => {
					return request;
				})
			);
		}
	}
}
