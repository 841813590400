import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, ID, StoreConfig} from '@datorama/akita';
import {InstagramPost, IntegrationInstagram} from '../../../core/models/instagram';
import {Moment} from 'moment-timezone';
import {LocalStorageKeys} from '../../../../global';

export interface InstagramStoreState extends EntityState<InstagramPost>, ActiveState {
	pagingCursors: { [id: string]: string };
	canLoadMore: { [id: string]: string };
	isLoading: { [id: string]: boolean };
	token: string;
	machineId: string;
	tokenExpirationDate: Moment;
	integration: IntegrationInstagram;
}

const initialState = {
	pagingCursors: {},
	canLoadMore: {},
	isLoading: {},
	token: null,
	machineId: localStorage.getItem(LocalStorageKeys.INSTAGRAM_MACHINE_ID),
	tokenExpirationDate: null,
	integration: null,
};

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'instagram', resettable: true})
export class InstagramStore extends EntityStore<InstagramStoreState> {

	constructor() {
		super(initialState);
	}

	setIsLoading(id: ID, isLoading: boolean) {
		this.update(state => ({
			...state,
			isLoading: {
				...state.isLoading,
				[id.toString()]: isLoading
			}
		}));
	}
}

