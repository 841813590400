import {ID} from "@datorama/akita";
import {SocialWallPostState} from './social-wall-post';

export enum NotificationType {
	FEED = 0,
	BOOKING_CANCELED = 3,
	SOCIAL_WALL_POST_CHANGE = 4,
	SOCIAL_WALL_POST_RANKED = 5,
	SOCIAL_WALL_POST_DELETED = 6,
	LEADERBOARD_UPDATED = 7
}

export interface Notification {
	id: ID;
	data: {
		id?: ID;
		title: string;
		body: string;
		image?: string;
		likes?: number;
		dislikes?: number;
		state?: SocialWallPostState;
		first_name?: string;
		last_name?: string;
		position?: string;
		company?: string;
		photo?: string;
		order?: number;
		total?: number;
	};
	eventId: number
	type: NotificationType;
	feedId?: number
}

export interface LeaderboardNotification {
	id: ID;
	type: NotificationType,
	data: {
		id: number,
		firstName: string,
		lastName: string,
		name: string,
		position: string,
		company: string,
		photo: string,
		order: number,
		total: number
	}[]
}
