/*
 * Timezones
 */

let timeZone: string = 'UTC';

export function getTimeZone() {
	return timeZone;
}

export function setTimeZone(timeZoneToSet: string) {
	timeZone = timeZoneToSet;
}

export default {getTimeZone, setTimeZone};

/*
 * Local storage
 */

export enum LocalStorageKeys {
	TOKEN = 'token',
	SESSION_NUMBER = 'session-number',
	LANG = 'lang',
	SEEN_BEFORE = 'seen-before-',
	SEEN_DURING = 'seen-during-',
	SEEN_APP_MODAL = 'seen-app-modal',
	QUESTION_USERNAME = 'question-username',
	INSTAGRAM_TOKEN = 'instagram-token',
	INSTAGRAM_MACHINE_ID = 'instagram-machine-id',
	SEEN_OWN_POSTS = 'seen-own-post',
	REDIRECT_TO = 'redirect-to',
	SEEN_NEWSFEED = 'seen-newsfeed-',
	SEEN_SOCIAL_WALL_POST = 'sw-post-',
}

export const LOCALSTORAGE_AGENDA_SCHEDULE_TYPE = (eventId) => 'agenda-schedule-type-' + eventId;
