import {Injectable} from '@angular/core';
import {AuthStore} from './auth.store';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Observable, of, throwError} from 'rxjs';
import {
	createUser,
	createUserAuthInfo,
	createUserToServer,
	User,
	UserAuthInfo,
	UserAuthInfoFromServer,
	UserFromServer
} from '../../core/models/user';
import {ID, transaction} from '@datorama/akita';
import {SocialService} from '../../libraries/social/social.service';
import {cleanObject} from '../../shared/utils/clean-object';
import {TranslateService} from '@ngx-translate/core';
import {getUUID} from '../../shared/utils/uuid';
import {LocalStorageKeys} from '../../../global';
import {EventsStore} from '../events/events.store';
import Bugsnag from "@bugsnag/js";
import {SessionsQuery} from "../sessions/sessions.query";
import {EventeeHttpClient} from '../../core/services/http/eventee.http-client';
import {FeedsService} from "../feeds/feeds.service";
import {SocialWallService} from "../socials/social-wall/social-wall.service";

export interface ResetPasswordRequest {
	email: string;
	token: string;
	password: string;
	password_confirmation: string;
}

@Injectable({providedIn: 'root'})
export class AuthService {

	private suffixV1 = '/v1';
	private suffixV2 = '/v2';

	constructor(
		private authStore: AuthStore,
		private socialService: SocialService,
		private socialWallService: SocialWallService,
		private eventsStore: EventsStore,
		private feedsService: FeedsService,
		private http: EventeeHttpClient) {
	}

	refresh() {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/token`).pipe(
			map((response: any) => response.token),
			tap(token => {
				this.authStore.setError(null);
				this.setToken(token);
			}),
			catchError((error: HttpErrorResponse) => {
				this.authStore.setLoading(false);
				this.removeToken();
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	login(credentials): Observable<UserAuthInfo> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/login`, cleanObject({
			...credentials,
			session_number: localStorage.getItem(LocalStorageKeys.SESSION_NUMBER)
		})).pipe(
			map((response: UserAuthInfoFromServer) => {
				const userAuthInfo = createUserAuthInfo(response);
				this.setToken(userAuthInfo.token);
				this.authStore.setError(null);
				return userAuthInfo;
			}),
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) {
					this.authStore.setError(error.error.password);
					return of();
				}

				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	facebookLogin(): Observable<UserAuthInfo> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.socialService.facebook().pipe(
			map((response: UserAuthInfoFromServer) => {
				const userAuthInfo = createUserAuthInfo(response);
				this.setToken(userAuthInfo.token);
				this.authStore.setError(null);
				return userAuthInfo;
			}),
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	linkedInLogin(): Observable<UserAuthInfo> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.socialService.linkedIn().pipe(
			map((response: UserAuthInfoFromServer) => {
				const userAuthInfo = createUserAuthInfo(response);
				this.setToken(userAuthInfo.token);
				this.authStore.setError(null);
				return userAuthInfo;
			}),
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	appleLogin(): Observable<UserAuthInfo> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.socialService.apple().pipe(
			map((response: UserAuthInfoFromServer) => {
				const userAuthInfo = createUserAuthInfo(response);
				this.setToken(userAuthInfo.token);
				this.authStore.setError(null);
				return userAuthInfo;
			}),
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	anonymousLogin(): Observable<UserAuthInfo> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		let sessionNumber = localStorage.getItem(LocalStorageKeys.SESSION_NUMBER);
		if (!sessionNumber) {
			sessionNumber = 'b' + getUUID();
		}

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/login/anonymous`, cleanObject({
			session_number: sessionNumber
		})).pipe(
			map((response: UserAuthInfoFromServer) => {
				const userAuthInfo = createUserAuthInfo(response);
				this.setToken(userAuthInfo.token);
				this.authStore.setError(null);
				return userAuthInfo;
			}),
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) {
					this.authStore.setError(error.error.password);
					return of();
				}

				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	@transaction()
	logout() {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV2}/logout`, null).pipe(
			tap((response: any) => {
				this.authStore.setError(null);
			}),
			finalize(() => {
				this.feedsService.removeAllSeenFeeds();
				this.socialWallService.removeAllSeenPosts();
				localStorage.removeItem(LocalStorageKeys.SEEN_OWN_POSTS);
				localStorage.removeItem(LocalStorageKeys.QUESTION_USERNAME);
				this.removeToken();
				this.authStore.setLoading(false);
				this.eventsStore.resetUi();
			})
		);
	}

	me(): Observable<User> {
		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/me`).pipe(
			map((user: UserFromServer) => createUser(user)),
			tap(user => {
				Bugsnag.setUser(user.id.toString(), user.email, user.name)
				this.authStore.update({user});
				// if user removes token from local storage manually, this will re-set it.
				if (!localStorage.getItem(LocalStorageKeys.TOKEN)) {
					localStorage.setItem(LocalStorageKeys.TOKEN, this.authStore.getValue().token);
				}
				if (user.isAnonymous) {
					localStorage.setItem(LocalStorageKeys.SESSION_NUMBER, user.email);
				}
			}),
			catchError((error: HttpErrorResponse) => {
				console.error(error);

				if (error.status !== 401) {
					this.removeToken();
					return of(null);
				}

				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	updateProfile(user: User): Observable<User> {
		const subscribed = user.subscribed;
		const data = cleanObject(createUserToServer(user));
		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV2}/me`, data).pipe(
			map((response: UserFromServer) => {
				// TODO: do better
				const u = createUser(response);
				u.subscribed = subscribed;
				return u;
			}),
			tap(userResponse => this.authStore.updateUserProfile(userResponse)),
			catchError((error: HttpErrorResponse) => {
				console.error(error);

				if (error.status !== 401) {
					return of(null);
				}

				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	mergeProfile(user: User, mergedAttendeeId: number): Observable<User> {
		user.attendeeId = mergedAttendeeId;
		return this.updateProfile(user).pipe(
			tap(() => {
				this.authStore.updateImportedProfile(null);
			})
		);
	}

	registerInvitation(user: User): Observable<User> {
		const sessionNumber = localStorage.getItem(LocalStorageKeys.SESSION_NUMBER);
		if (!!sessionNumber) {
			user.sessionNumber = sessionNumber;
		}
		return this.updateProfile(user);
	}

	getImportedProfile(eventId: ID): Observable<User> {
		this.eventsStore.setLoading(true);
		this.eventsStore.setError(null);

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/me/${eventId}/profile`).pipe(
			map((response: UserFromServer) => {
				let result = null;
				if (!!response) {
					result = createUser(response);
				}
				this.eventsStore.setError(null);
				this.authStore.updateImportedProfile(result);
				return result;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.eventsStore.setLoading(false))
		);
	}


	register(credentials): Observable<UserAuthInfo> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV2}/register`, cleanObject({
			...credentials,
			session_number: localStorage.getItem(LocalStorageKeys.SESSION_NUMBER)
		})).pipe(
			map((response: UserAuthInfoFromServer) => {
				const userAuthInfo = createUserAuthInfo(response);
				this.setToken(userAuthInfo.token);
				this.authStore.setError(null);
				return userAuthInfo;
			}),
			catchError((error: HttpErrorResponse) => {
				if (error.status === 422) {
					this.authStore.setError('register_email_already_exist_warning');
					return of();
				}

				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	forgotPassword(email: string) {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.put(`${environment.appApi.baseUrl}${this.suffixV1}/reset/send`, {
			email
		}).pipe(
			tap(() => {
				this.authStore.setError(null);
			}),
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	validateResetPassword(token: string) {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/reset/validate?token=${token}`).pipe(
			tap((response: any) => {
				this.authStore.setError(null);
			}),
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	resetPassword(request: ResetPasswordRequest) {
		return this.http.patch(`${environment.appApi.baseUrl}${this.suffixV1}/reset`, {
			...cleanObject(request)
		}).pipe(
			tap((response: any) => {
				this.authStore.setError(null);
			}),
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	validateEventInvitation(token: string) {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/invitation/validate?token=${token}`).pipe(
			tap((response: any) => {
				this.authStore.setError(null);
			}),
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	changeEmail(email: string): Observable<User> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/me/change/email`, {
			email
		}).pipe(
			map((response: UserFromServer) => {
				const user = createUser(response);
				this.authStore.setError(null);
				this.authStore.updateUserProfile(user);
				return user;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	changePassword(oldPassword: string, newPassword: string): Observable<User> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/me/change/password`, {
			old_password: oldPassword,
			new_password: newPassword
		}).pipe(
			map((response: UserFromServer) => {
				const user = createUser(response);
				this.authStore.setError(null);
				this.authStore.updateUserProfile(user);
				return user;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	deleteAccount(email: string): Observable<HttpResponse<object>> {
		this.authStore.setLoading(true);
		this.authStore.setError(null);

		return this.http.delete(`${environment.appApi.baseUrl}${this.suffixV1}/me`, {
			params: {
				email: encodeURIComponent(email)
			},
			observe: 'response'
		}).pipe(
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.authStore.setLoading(false))
		);
	}

	setToken(token: string) {
		localStorage.setItem(LocalStorageKeys.TOKEN, token);
		this.authStore.update(({
			token
		}));
	}

	removeToken() {
		localStorage.removeItem(LocalStorageKeys.TOKEN);
		this.eventsStore.updateActive({
			role: null
		});
		this.authStore.update({
			token: null
		});
	}
}
