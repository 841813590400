import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter, map, share, startWith} from 'rxjs/operators';

export interface LocalStorageItem {
	key: string;
	value: any;
}

@Injectable({
	providedIn: 'root'
})
export class StorageService implements OnDestroy {
	public onSubject = new Subject<LocalStorageItem>();
	public changes = this.onSubject.asObservable().pipe(
		startWith(...this.getStorage())
	);

	constructor() {
		this.start();
	}

	ngOnDestroy() {
		this.stop();
	}

	public getStorage() {
		const s = [];
		for (let i = 0; i < localStorage.length; i++) {
			try {
				s.push({
					key: localStorage.key(i),
					value: JSON.parse(localStorage.getItem(localStorage.key(i)))
				});
			} catch (e) {
				s.push({
					key: localStorage.key(i),
					value: localStorage.getItem(localStorage.key(i))
				});
			}
		}
		return s as LocalStorageItem[];
	}

	public store(key: string, data: any): void {
		if (typeof data === 'object') {
			localStorage.setItem(key, JSON.stringify(data));
		} else {
			localStorage.setItem(key, data);
		}
		// the local application doesn't seem to catch changes to localStorage...
		this.onSubject.next({key, value: data});
	}

	public clear(key) {
		localStorage.removeItem(key);
		// the local application doesn't seem to catch changes to localStorage...
		this.onSubject.next({key, value: null});
	}

	private start(): void {
		window.addEventListener('storage', this.storageEventListener.bind(this));
	}

	private storageEventListener(event: StorageEvent) {
		if (event.storageArea == localStorage) {
			let v;
			try {
				v = JSON.parse(event.newValue);
			} catch (e) {
				v = event.newValue;
			}
			this.onSubject.next({key: event.key, value: v} as LocalStorageItem);
		}
	}

	private stop(): void {
		window.removeEventListener('storage', this.storageEventListener.bind(this));
		this.onSubject.complete();
	}
}
