import * as moment from 'moment-timezone';
import {getTimeZone} from '../../../global';
import {Moment} from 'moment';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

export function dateTimeFromServerInLocalTimezone(dateTime: string) {
	return moment.tz(dateTime, 'YYYY-MM-DD HH:mm:ss', 'UTC').local();
}

export function dateTimeFromServerInUTC(dateTime: string) {
	return moment.tz(dateTime, 'YYYY-MM-DD HH:mm:ss', 'UTC');
}

export function dateTimeFromServer(dateTime: string, timezone?: string) {
	return moment.tz(dateTime, 'YYYY-MM-DD HH:mm:ss', 'UTC').tz(timezone || getTimeZone());
}

export function dateTimeToServer(date: Moment) {
	return date.clone().utc().format('YYYY-MM-DD HH:mm:ss');
}

export function dateToServer(date: Moment) {
	if (!date.isUTC()) {
		date = date.clone().utc();
	}

	return date.format('YYYY-MM-DD');
}

export function ceil(time: Moment, ceilBy: DurationConstructor) {
	return time.subtract(1, 'millisecond').add(1, ceilBy).startOf(ceilBy);
}

export function floor(time: Moment, floorBy: DurationConstructor) {
	return time.startOf(floorBy);
}

export function dateIntervalFormatter(start: Moment, end: Moment) {
	if (!start.isSame(end, 'year')) {
		return start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY');
	} else if (!start.isSame(end, 'month')) {
		return start.format('D MMMM') + ' - ' + end.format('D MMMM, YYYY');
	} else if (!start.isSame(end, 'day')) {
		return start.format('D') + ' - ' + end.format('D MMMM, YYYY');
	} else {
		// single day
		return start.format('D MMMM, YYYY');
	}
}
