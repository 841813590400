import * as moment from 'moment';
import {Moment} from 'moment';
import {ID} from '@datorama/akita';
import {Image} from './image';

export enum FeedInteraction {
	VIEWED = 'v',
	CLICKED = 'c'
}

export enum FeedRank {
	LIKED = 'l',
	DISLIKED = 'd',
	NO_RANK = 'n'
}

export interface Feed {
	id: ID;
	title: string;
	description: string;
	sendTime: Moment;
	pushDescription: string;
	sent: number;
	comments: any[];
	likes: number;
	dislikes: number;
	viewed: number;
	clicked: number;
	rank: FeedRank;
	photo: string;
	mediaPhoto?: Image;
	hasSeen?: boolean
}

export interface FeedFromServer {
	id?: number;
	title?: string;
	description?: string;
	send_time?: string;
	push?: boolean;
	push_description?: string;
	sent?: number;
	comments?: any[];
	likes?: number;
	dislikes?: number;
	viewed?: number;
	clicked?: number;
	rank: FeedRank;
	photo?: string;
	media_photo?: Image;
	media_id?: ID;
}

export function createFeed(params: Partial<FeedFromServer>) {
	return {
		id: params.id,
		title: params.title,
		description: params.description,
		sendTime: moment.utc(params.send_time).local(),
		pushDescription: params.push_description,
		sent: params.sent,
		comments: params.comments,
		likes: params.likes,
		dislikes: params.dislikes,
		viewed: params.viewed,
		clicked: params.clicked,
		rank: params.rank,
		photo: params.photo,
		mediaPhoto: params.media_photo,
		hasSeen: false
	} as Feed;
}

