import {Injectable} from '@angular/core';
import {ActiveState, Constructor, EntityState, EntityStore, getEntityType, ID, StoreConfig} from '@datorama/akita';
import {Session} from '../../core/models/session';
import {Stream} from '../../core/models/stream';

export interface SessionsState extends EntityState<Session>, ActiveState {
	livePolls: any[];
	favorites: Set<ID>;
}

const initialState = {
	livePolls: []
};

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'session', resettable: true})
export class SessionsStore extends EntityStore<SessionsState> {

	constructor() {
		super(initialState);
	}

	updateUI( ui: Partial<SessionsState['ui']>) {
		this.update(state => ({
			ui: {
				...state.ui,
				...ui
			}
		}));
	}

	updateLivePolls( livePolls: Partial<SessionsState['livePolls']>) {
		this.update(state => ({
			...state,
			livePolls
		}));
	}

	updateFavorites( favorites: Partial<SessionsState['favorites']>) {
		this.update(state => ({
			...state,
			favorites
		}));
	}

}

