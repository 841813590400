import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EventeeRequestInterceptor} from './core/interceptors/eventee-request.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgSelectModule} from '@ng-select/ng-select';
import {I18nModule} from './i18n/i18n.module';
import {NotFoundComponent} from './domains/general/pages/not-found/not-found.component';
import {
	MobileAppDownloadModalComponent
} from './shared/components/_modals/mobile-app-download-modal/mobile-app-download-modal.component';
import {AlertModule} from 'ngx-bootstrap/alert';
import {EVENTEE_HTTP_INTERCEPTORS, EventeeHttpClient} from './core/services/http/eventee.http-client';
import {FacebookRequestInterceptor} from './core/interceptors/facebook-request.interceptor';
import {FACEBOOK_HTTP_INTERCEPTORS, FacebookHttpClient} from './core/services/http/facebook-http-client.service';
import {AuthService} from './store/auth';
import {FeatureFlagsService} from './libraries/feature-flags/feature-flags.service';
import {Router} from '@angular/router';
import {eventeeInitializerFactory} from './shared/utils/eventee-initializer';
import {FeatureFlagOverridesComponent} from './domains/feature-flag-overrides/feature-flag-overrides.component';
import {DuplicateCallInterceptor} from './core/interceptors/duplicate-call.interceptor';
import {CDNHttpClient} from "./core/services/http/cdn.http-client";

@NgModule({
	declarations: [
		AppComponent,
		NotFoundComponent,
		MobileAppDownloadModalComponent,
		FeatureFlagOverridesComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		ReactiveFormsModule,
		HttpClientModule,

		// Commonly used modules
		I18nModule,
		ToastrModule.forRoot({
			preventDuplicates: true,
			autoDismiss: true,
		}),
		ModalModule.forRoot(),
		AlertModule.forRoot(),
		NgSelectModule
	],
	providers: [
		CDNHttpClient, EventeeRequestInterceptor, FacebookRequestInterceptor,
		{
			provide: EVENTEE_HTTP_INTERCEPTORS,
			useClass: EventeeRequestInterceptor,
			multi: true,
		},
		{
			provide: FACEBOOK_HTTP_INTERCEPTORS,
			useClass: FacebookRequestInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DuplicateCallInterceptor,
			multi: true,
		},
		// [{provide: ErrorHandler, useClass: ErrorsHandler}],
		EventeeHttpClient,
		FacebookHttpClient,
		{
			provide: APP_INITIALIZER,
			useFactory: eventeeInitializerFactory,
			deps: [AuthService, FeatureFlagsService, Router],
			multi: true
		},
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor() {
	}
}
