import {Injectable} from '@angular/core';
import {combineQueries, QueryConfig, QueryEntity, toBoolean} from '@datorama/akita';
import {NetworkingV2State, NetworkingV2Store} from './networking-v2.store';
import {Observable} from 'rxjs';
import {NetworkingUser} from '../../core/models/networking-user';
import {debounceTime, map} from 'rxjs/operators';
import {StorageService} from '../../libraries/storage/storage.service';
import {AuthQuery} from "../auth";

@Injectable({providedIn: 'root'})
@QueryConfig({
	sortBy: (a: NetworkingUser, b: NetworkingUser, store) => {
		if (!b?.lastMessage) {
			return -1;
		} else if (!a?.lastMessage) {
			return 1;
		} else {
			return b?.lastMessage?.createdAt.diff(a?.lastMessage?.createdAt);
		}
	}
})
export class NetworkingV2Query extends QueryEntity<NetworkingV2State> {

	isJoined$ = this.select(state => toBoolean(state.ui.joined));

	friends$ = this.selectAll();

	unreadMessagesCount$: Observable<number> = this.friends$.pipe(
		debounceTime(100),
		map((friends) => friends.filter(friend =>
			!friend.lastMessage.isRead && friend.lastMessage.toUserId === this.authQuery.getMyId()).length)
	);

	totalUserRequestsCount$: Observable<number> = this.select(state => state.ui.userRequestsTotal);

	showNetworkingBadge$: Observable<boolean> = combineQueries([
		this.unreadMessagesCount$,
		this.totalUserRequestsCount$
	]).pipe(
		map(([unreadMessagesCount, totalRequestsCount]) => (unreadMessagesCount + totalRequestsCount) > 0)
	);

	constructor(
		protected store: NetworkingV2Store,
		private authQuery: AuthQuery
	) {
		super(store);
	}

	isJoined() {
		return toBoolean(this.getValue()?.ui?.joined);
	}
}
