import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthQuery, AuthService} from '../../store/auth';
import {Observable} from 'rxjs';
import {sha256} from '../../shared/utils/sha256';
import {environment} from '../../../environments/environment';

export const canActivateSecretAccess = (next: ActivatedRouteSnapshot,
										state: RouterStateSnapshot) => {
	if (!environment.production) {
		return true;
	}

	const code = prompt('Enter passcode');

	if (code !== null && sha256(code) === '92b690fedfae7ea8024eb6ea6d53f64cd0a4d20e44acf71417dca4f0d28f5c74') {
		return true;
	} else {
		inject(Router).navigate(['auth']);
		return false;
	}
};
