import {Injectable} from '@angular/core';
import {combineQueries, ID, Query, QueryConfig, QueryEntity} from '@datorama/akita';
import {InstagramStore, InstagramStoreState} from './instagram.store';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {IntegrationInstagram} from '../../../core/models/instagram';

@Injectable({providedIn: 'root'})
@QueryConfig({
	sortBy: 'addedAt'
})
export class InstagramQuery extends QueryEntity<InstagramStoreState> {

	integration$: Observable<IntegrationInstagram> = this.select('integration');
	isLoading$ = (id: ID) => this.select(state => !!state?.isLoading[id]);
	canLoadMore$ = (id: ID) => this.select(state => !!state?.canLoadMore[id]);
	instagramPostsById$ = (id: ID) => this.selectAll({filterBy: igPost => igPost.source === id});
	hasNewInstagramPosts$ = (id: ID) => this.selectAll({filterBy: igPost => igPost.source === id && !igPost.visible}).pipe(
		map(data => data.length > 0)
	);

	constructor(
		protected store: InstagramStore,
	) {
		super(store);
	}

	isLoading(id: ID): boolean {
		return !!this.store.getValue()?.isLoading?.[id];
	}

	getAfter(id: ID): string {
		return this.store.getValue()?.pagingCursors?.[id];
	}

	getMachineId() {
		return this.store.getValue()?.machineId;
	}

	getIntegration() {
		return this.store.getValue()?.integration;
	}
}
