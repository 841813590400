export const environment = {
	production: false,
	cname: false,

	web: {
		baseUrl: 'https://eventee.dev/',
	},

	socket: {
		socketUrl: 'https://socket.eventee.dev',
		questions_namespace: '/questions',
		polls_namespace: '/polls',
		reconnect: 3000
	},

	admin: {
		baseUrl: 'https://admin.eventee.dev/',
	},

	appApi: {
		baseUrl: 'https://api.eventee.dev',
		analyticsBaseUrl: 'https://analytics.eventee.dev'
	},

	scheduleConfig: {
		hourHeight: 250,
		startHour: 9,
		endHour: 21
	},

	socials: {
		redirectUri: 'https://event.eventee.dev/o-auth-redirect/',
		facebookId: '1853045354914713',
		linkedInId: '7732ezyfw4gbqz',
		eventbriteId: 'UOD2FEXVUAWASGYA63',
		appleId: 'com.touchart.eventee.dev.login'
	},

	googleMaps: 'AIzaSyAWhE7yKRCYDVJhbZrHmfTbspwF5t5OBpI',

	webRTC: {
		iceServers:  [
			{urls: 'stun:stun.l.google.com:19302'},
			{urls: 'turn:numb.viagenie.ca', username: 'matej@eventee.co', credential: 'vafsyb-cowkyg-Xevni3'}
		]
	},

	bugsnag: {
		stage: 'staging',
		key: '429401a71b1504a56a88d7d0700d1516'
	},

	cacheTTL: 5 * 60 * 1000, // 5 MINUTES

	growthBook: {
		url: 'https://cdn.growthbook.io/api/features/dev_M5y97ohhtqDB547pPVx8KtWeHFYxm8xzG2WVXw6Z3A',
		key: null
	}
};
