import {Injectable} from '@angular/core';
import {QueryConfig, QueryEntity} from '@datorama/akita';
import {DaysState, EventDaysStore} from './event-days.store';
import {EventDay} from '../../core/models/event-day';

@Injectable({providedIn: 'root'})
@QueryConfig({
	sortBy: (a: EventDay, b: EventDay, store) => {
		return a.date.diff(b.date);
	}
})
export class EventDaysQuery extends QueryEntity<DaysState> {

	constructor(
		protected store: EventDaysStore) {
		super(store);
	}

}
