import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';

@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

	constructor(
		private titleService: Title,
		private translate: TranslateService
	) {
		this.translate.get('global_page_404').pipe(
			take(1)
		).subscribe(value => {
			this.titleService.setTitle(value);
		});
	}

	ngOnInit(): void {
	}

}
