import {NgModule} from '@angular/core';
import {provideRouter, RouterModule} from '@angular/router';
import {eventGuard} from './core/guards/event.guard';
import {NotFoundComponent} from './domains/general/pages/not-found/not-found.component';
import {OAuthRedirectComponent} from './domains/o-auth-redirect/o-auth-redirect.component';
import {getMainRoutePath} from './shared/utils/cname-helpers';
import {FeatureFlagOverridesComponent} from './domains/feature-flag-overrides/feature-flag-overrides.component';
import {canActivateSecretAccess} from './core/guards/secret-access.guard';
import {eventDetailGuard} from './core/guards/event-detail.guard';

@NgModule({
	providers: [
		provideRouter([
			{
				path: getMainRoutePath(),
				canActivate: [eventGuard],
				children: [
					{
						path: '',
						pathMatch: 'full',
						redirectTo: 'detail'
					},
					{
						path: 'auth',
						loadChildren: () => import('src/app/domains/auth/auth.module')
							.then(m => m.AuthModule)
					},
					{
						path: 'detail',
						canActivate: [eventDetailGuard],
						loadChildren: () => import('src/app/domains/detail/detail.module')
							.then(m => m.DetailModule),
					},
					// {
					// 	path: 'call/:userId',
					// 	canActivate: [EventDetailGuard],
					// 	resolve: {user: NetworkingUserResolver},
					// 	loadChildren: () => import('src/app/domains/call/call.module')
					// 		.then(m => m.CallModule)
					// },
				]
			},
			{path: 'o-auth-redirect', component: OAuthRedirectComponent},
			{path: '404', component: NotFoundComponent},
			{path: 'ff', component: FeatureFlagOverridesComponent, canActivate: [canActivateSecretAccess]},
			{path: '**', redirectTo: '/404'}
		])
	],
	exports: [
		RouterModule
	]
})

export class AppRoutingModule {
}
