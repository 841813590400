import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {CustomString} from '../../core/models/custom-string';

export interface CustomStringState extends EntityState<CustomString>, ActiveState {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'events', resettable: true, idKey: 'type'})
export class CustomStringsStore extends EntityStore<CustomStringState> {

	constructor() {
		super();
	}

}

