import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { FeatureFlagType } from 'src/app/libraries/feature-flags/feature-flags';
import { FeatureFlagsService } from 'src/app/libraries/feature-flags/feature-flags.service';

@Component({
	selector: 'app-feature-flag-overrides',
	templateUrl: './feature-flag-overrides.component.html',
	styleUrls: ['./feature-flag-overrides.component.scss']
})
export class FeatureFlagOverridesComponent implements OnInit {

	FeatureFlagType = FeatureFlagType;

	constructor(
		public featureFlagsService: FeatureFlagsService
	) {
	}

	ngOnInit(): void {
	}

}
