import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ID} from '@datorama/akita';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, finalize, map, share, tap} from 'rxjs/operators';
import {NetworkingStore} from './networking.store';
import {Menu} from '../../core/models/menu';
import {createNetworkingUser, NetworkingUser, NetworkingUserFromServer} from '../../core/models/networking-user';
import {createUser, User, UserFromServer} from '../../core/models/user';
import {SocketService} from '../../libraries/socket/socket.service';
import {EventeeHttpClient} from '../../core/services/http/eventee.http-client';

export enum NetworkingMatchReaction {
	REJECT,
	REQUEST
}

@Injectable({
	providedIn: 'root'
})
export class NetworkingService {

	private suffixV1 = '/v1';
	private suffixV2 = '/v2';
	private suffixEvent = '/event';
	private suffixNetworking = '/networking';

	constructor(
		private networkingStore: NetworkingStore,
		private http: EventeeHttpClient) {
	}

	joinNetworking(eventId: ID): Observable<any> {
		this.networkingStore.setLoading(true);
		this.networkingStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV2}${this.suffixEvent}/${eventId}${this.suffixNetworking}`, {}).pipe(
			tap((response) => {
				this.networkingStore.setError(null);
				this.setJoinedNetworking(true);
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.networkingStore.setLoading(false))
		);
	}

	getMyFriends(eventId: ID): Observable<NetworkingUser[]> {
		this.networkingStore.setLoading(true);
		this.networkingStore.setError(null);

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV2}${this.suffixEvent}/${eventId}${this.suffixNetworking}/friends`).pipe(
			map((response: NetworkingUserFromServer[]) => {
				const result = response.map(u => createNetworkingUser(u));
				this.networkingStore.setError(null);
				this.networkingStore.set(result);
				return result;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.networkingStore.setLoading(false))
		);
	}

	getUsersToMatch(eventId: ID, limit: number, rejected?: boolean) {
		this.networkingStore.setLoading(true);
		this.networkingStore.setError(null);

		const suffix = rejected ? 'rejected' : 'users';

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV2}${this.suffixEvent}/${eventId}${this.suffixNetworking}/${suffix}?limit=${limit}`).pipe(
			map((response: {count: number, users: UserFromServer[]}) => {
				const result = response?.users?.map(u => createUser(u));
				this.networkingStore.setError(null);
				this.networkingStore.updateUI({
					usersToMatch: result || []
				});

				this.networkingStore.setTotalUsersCount(response.count);

				return response;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.networkingStore.setLoading(false))
		);
	}

	setJoinedNetworking(value: boolean) {
		this.networkingStore.updateUI({
			joined: value
		});
	}

	addRejectedUser(user: User) {
		this.networkingStore.addRejectedUser(user);
	}

	clearRejectedUsers() {
		this.networkingStore.updateUI({
			rejectedUsers: new Set()
		});
	}

	match(eventId: ID, userId: ID, reaction: NetworkingMatchReaction) {
		this.networkingStore.setLoading(true);
		this.networkingStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV2}${this.suffixEvent}/${eventId}${this.suffixNetworking}/match`, {
			user_id: userId, reaction
		}).pipe(
			map((response: {matched: boolean}) => {

				this.networkingStore.update(state => {
					const usersToMatch = state.ui.usersToMatch.slice(1, state.ui.usersToMatch.length);

					// If the user is rejecting user, we dont need to remove one from total, because its done by 'RejectedUsers' set
					let usersToMatchTotal = state.ui.usersToMatchTotal;
					if (reaction === NetworkingMatchReaction.REQUEST) {
						usersToMatchTotal--;
					}

					return {
						...state,
						ui: {
							...state.ui,
							usersToMatchTotal,
							usersToMatch
						}
					};
				});

				return response;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.networkingStore.setLoading(false))
		);
	}

	matchV2(eventId: ID, userId: ID, reaction: NetworkingMatchReaction) {
		this.networkingStore.setLoading(true);
		this.networkingStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixEvent}/${eventId}${this.suffixNetworking}/match`, {
			user_id: userId, reaction
		}).pipe(
			map((response: {matched: boolean}) => {

				this.networkingStore.update(state => {
					const usersToMatch = state.ui.usersToMatch.slice(1, state.ui.usersToMatch.length);

					// If the user is rejecting user, we dont need to remove one from total, because its done by 'RejectedUsers' set
					let usersToMatchTotal = state.ui.usersToMatchTotal;
					if (reaction === NetworkingMatchReaction.REQUEST) {
						usersToMatchTotal--;
					}

					return {
						...state,
						ui: {
							...state.ui,
							usersToMatchTotal,
							usersToMatch
						}
					};
				});

				return response;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.networkingStore.setLoading(false))
		);
	}

	setActiveNetworkingUser(id: ID) {
		this.networkingStore.setActive(id);
	}
}
