import {Injectable} from '@angular/core';
import {ID, Query, toBoolean} from '@datorama/akita';
import {AuthState, AuthStore} from './auth.store';
import {User} from '../../core/models/user';
import {Observable} from 'rxjs';
import {LocalStorageKeys} from "../../../global";

@Injectable({providedIn: 'root'})
export class AuthQuery extends Query<AuthState> {

	isLoggedIn$ = this.select(state => toBoolean(state.token));
	user$: Observable<User> = this.select(state => state.user);
	importedProfile$: Observable<User> = this.select(state => state.importedProfile);
	hasImportedProfile$: Observable<boolean> = this.select(() => this.hasProfileToMerge());
	token$: Observable<string> = this.select(state => state.token);

	constructor(
		protected store: AuthStore
	) {
		super(store);
	}

	isLoggedIn() {
		return toBoolean(this.getValue().token);
	}

	isAnonymous() {
		return toBoolean(this.getValue()?.user?.isAnonymous);
	}

	getMyId(): ID {
		return this.getValue()?.user?.id;
	}

	getUser(): User {
		return this.getValue()?.user;
	}

	hasProfileToMerge() {
		const importedProfile = this.getValue()?.importedProfile;
		return importedProfile && !importedProfile.used;
	}

	getUserPhoto() {
		return this.getUser()?.thumbnail;
	}

	getToken() {
		return localStorage.getItem(LocalStorageKeys.TOKEN);
	}
}
