import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {OAuthConfig, OAuthService} from '../o-auth/o-auth.service';
import {switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AppleIdAuthService} from '../apple-id-auth/apple-id-auth.service';
import {cleanObject} from '../../shared/utils/clean-object';
import {LocalStorageKeys} from '../../../global';
import {EventsQuery} from '../../store/events/events.query';

@Injectable({
	providedIn: 'root'
})
export class SocialService {
	private facebookConfig: OAuthConfig;
	private linkedInConfig: OAuthConfig;

	private suffixV1 = '/v1';
	static redirectUri = environment.socials.redirectUri;

	constructor(
		public oAuthService: OAuthService,
		private appleIdAuthService: AppleIdAuthService,
		private eventsQuery: EventsQuery,
		private http: HttpClient
	) {
		this.facebookConfig = {
			clientId: environment.socials.facebookId,
			authorizationEndpoint: 'https://www.facebook.com/v3.3/dialog/oauth',
			redirectUri: SocialService.redirectUri,
			scope: ['public_profile', 'email', 'user_friends'],
			width: 520,
			height: 610
		};
		this.linkedInConfig = {
			clientId: environment.socials.linkedInId,
			authorizationEndpoint: 'https://www.linkedin.com/oauth/v2/authorization',
			redirectUri: SocialService.redirectUri,
			scope: ['r_liteprofile', 'r_emailaddress'],
			width: 527,
			height: 581
		};
	}

	facebook() {
		if (this.eventsQuery.getActive().hasCname) {
			this.facebookConfig.state = this.eventsQuery.getActiveId() + '';
		} else {
			delete this.facebookConfig.state;
		}
		return this.oAuthService.authorize(this.facebookConfig).pipe(
			switchMap((code) => this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/login/facebook`,
					cleanObject({
						code,
						redirect_uri: this.facebookConfig.redirectUri,
						session_number: localStorage.getItem(LocalStorageKeys.SESSION_NUMBER)
					})))
		);
	}

	linkedIn() {
		if (this.eventsQuery.getActive().hasCname) {
			this.linkedInConfig.state = this.eventsQuery.getActiveId() + '';
		} else {
			delete this.linkedInConfig.state;
		}
		return this.oAuthService.authorize(this.linkedInConfig).pipe(
			switchMap((code) => this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/login/linked_in`,
					cleanObject({
						code,
						redirect_uri: this.linkedInConfig.redirectUri,
						session_number: localStorage.getItem(LocalStorageKeys.SESSION_NUMBER)
					})))
		);
	}

	apple() {
		return this.appleIdAuthService.signIn(environment.socials.appleId, SocialService.redirectUri).pipe(
			switchMap((response) => {
				const params: any = {
					code: response.authorization.code,
					client_id: environment.socials.appleId,
					redirect_uri: SocialService.redirectUri
				};
				if (response?.user?.name) {
					params.first_name = response.user.name.firstName;
					params.last_name = response.user.name.lastName;
				}
				const sessionNumber = localStorage.getItem(LocalStorageKeys.SESSION_NUMBER);
				if (!!sessionNumber) {
					params.session_number = sessionNumber;
				}
				return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/login/apple`, params);
			})
		);
	}
}
