enum JoinErrors {
	WRONG_PIN = 'wrong_pin',
	NOT_WHITELISTED = 'not_whitelisted',
	ANONYMOUS_RESTRICTED = 'anonymous_restricted'
}

export function getRouteFromJoinError(error: string) {
	switch (error) {
		case JoinErrors.WRONG_PIN:
			return ['auth', 'pin'];
		case JoinErrors.NOT_WHITELISTED:
			return ['auth', 'not-whitelisted'];
		case JoinErrors.ANONYMOUS_RESTRICTED:
			return ['auth', 'login'];
	}
}
