import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, finalize, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {createFeed, Feed, FeedFromServer, FeedInteraction, FeedRank} from '../../core/models/feed';
import {FeedsStore} from './feeds.store';
import {ID} from '@datorama/akita';
import {EventsQuery} from "../events/events.query";
import {EventeeHttpClient} from '../../core/services/http/eventee.http-client';
import {Notification} from '../../core/models/notification';
import {LocalStorageKeys} from "../../../global";

@Injectable({
	providedIn: 'root'
})
export class FeedsService {

	private suffixV1 = '/v1';
	private suffixEvent = '/event';
	private suffixFeed = '/feed';

	constructor(
		private eventsQuery: EventsQuery,
		private feedsStore: FeedsStore,
		private http: EventeeHttpClient) {
	}

	getFeeds(eventId: ID): Observable<Feed[]> {
		this.feedsStore.setLoading(true);
		this.feedsStore.setError(null);

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixEvent}/${eventId}/feeds/sent`).pipe(
			map((response: FeedFromServer[]) => {
				const result = response.map(feed => this.createFeed(feed));
				this.feedsStore.setError(null);
				this.feedsStore.upsertMany(result);
				return result;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.feedsStore.setLoading(false))
		);
	}

	getFeed(feedId: ID): Observable<Feed> {
		this.feedsStore.setLoading(true);
		this.feedsStore.setError(null);

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixFeed}/${feedId}`).pipe(
			map((response: FeedFromServer) => {
				const feed = this.createFeed(response);
				this.feedsStore.setError(null);
				this.feedsStore.upsertMany([feed]);
				return feed;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.feedsStore.setLoading(false))
		);
	}

	rankFeed(feedId: ID, rank: FeedRank) {
		this.feedsStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixFeed}/${feedId}/rank`, {
			rank
		}).pipe(
			map((response: FeedFromServer) => {
				const result = this.createFeed(response);
				this.feedsStore.setError(null);
				this.feedsStore.upsertMany([result]);
				return result;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			})
		);
	}

	interactWithFeed(feedId: ID, interaction: FeedInteraction) {
		this.feedsStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixFeed}/${feedId}/interact`, {
			interaction
		}).pipe(
			map((response: FeedFromServer) => {
				this.addNewSeenFeed(response.id);
				const result = this.createFeed(response);
				this.feedsStore.setError(null);
				this.feedsStore.upsertMany([result]);
				return result;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			})
		);
	}

	handleNotification(notification: Notification) {
		notification.id = 'f' + notification.data.id;
		this.feedsStore.updateUI({
			shouldShowBadge: true
		});
		this.getFeed(notification.data.id).subscribe();
	}

	hideNewFeedBadge() {
		this.feedsStore.updateUI({
			shouldShowBadge: false
		});
	}

	removeAllSeenFeeds() {
		localStorage.removeItem(LocalStorageKeys.SEEN_NEWSFEED + this.eventsQuery.getActiveId());
	}

	private createFeed(feedFromServer: FeedFromServer) {
		const feed = createFeed(feedFromServer);
		feed.hasSeen = this.hasSeen(feed.id);
		return feed;
	}

	private addNewSeenFeed(feedId: ID) {
		const key = this.getLocalStorageKey();
		const viewedFeedIds = localStorage.getItem(key)?.split(',') ?? [];
		if (viewedFeedIds.indexOf(feedId + '') === -1) {
			viewedFeedIds.push(feedId + '')
			localStorage.setItem(key, viewedFeedIds.join(','));
		}
	}

	private hasSeen(feedId: ID) {
		const key = this.getLocalStorageKey();
		const viewedFeedIds = localStorage.getItem(key)?.split(',') ?? [];
		return viewedFeedIds.indexOf(feedId + '') > -1;
	}

	private getLocalStorageKey() {
		return LocalStorageKeys.SEEN_NEWSFEED + this.eventsQuery.getActiveId();
	}
}
