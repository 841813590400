import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Hall} from '../../core/models/hall';

export interface HallsState extends EntityState<Hall>, ActiveState {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'halls', resettable: true})
export class HallsStore extends EntityStore<HallsState> {

	constructor() {
		super();
	}
}

