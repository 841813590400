import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {User} from '../../core/models/user';

export interface AuthState {
	token: string;
	user: User;
	importedProfile: User;
}

export function createInitialState(): AuthState {
	return {
		token: localStorage.getItem('token'),
		user: null,
		importedProfile: null
	};
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'auth', resettable: true})
export class AuthStore extends Store<AuthState> {

	constructor(
	) {
		super(createInitialState());
	}

	updateUserProfile(user: AuthState['user']) {
		this.update(state => ({
			...state,
			user
		}));
	}

	updateImportedProfile(importedProfile: AuthState['importedProfile']) {
		this.update(state => ({
			...state,
			importedProfile
		}));
	}

}

