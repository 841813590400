import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, forkJoin, fromEvent} from 'rxjs';
import {calculateTop} from '../../shared/utils/calculate-top';

@Injectable({
	providedIn: 'root'
})
export class GlobalService {

	public sideNavVisible$ = new BehaviorSubject(true);
	public floatingPlayer$ = new BehaviorSubject(false);
	public width$ = new BehaviorSubject(window.innerWidth);
	public isMobile$ = new BehaviorSubject(false);
	public lastAgendaState: {
		x: number;
		y: number;
		headerPinned: boolean;
		direction: 'up' | 'down';
	} = {
		x: 0,
		y: 0,
		headerPinned: false,
		direction: 'up'
	};

	constructor() {
		combineLatest([
			this.sideNavVisible$,
			this.isMobile$
		]).subscribe(([visible, isMobile]) => {
			document.documentElement.style.setProperty('--sidenav-width',
				visible && !isMobile ? 'var(--sidenav-expanded-width)' : 'var(--sidenav-collapsed-width)');
		});
	}

	scrollTo(id) {
		const element = document.getElementById(id);
		const test = calculateTop(element);

		window.scrollTo({
			top: test,
			behavior: 'smooth'
		});
	}

}
