export enum ContentCDNType {
	CUSTOM_STRINGS = 'custom-strings',
	DAYS = 'days',
	DAY_CONTENT = 'day-content',
	HALLS = 'halls',
	MENUS = 'abouts',
	PARTNERS = 'partners',
	SPEAKERS = 'speakers',
	CATEGORIES = 'categories',
	TRACKS = 'tracks'
}

export interface ContentCDNFileFromServer {
	type: string,
	content_url: string
}

export interface ContentCDNFile {
	type: string,
	contentUrl: string
}

/**
 * A factory function that creates ContentCDNFile
 */
export function createContentCdnFile(params: Partial<ContentCDNFileFromServer>) {
	return {
		type: params.type,
		contentUrl: params.content_url
	} as ContentCDNFile;
}
