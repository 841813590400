import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ID} from '@datorama/akita';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {createNetworkingUser, NetworkingUser, NetworkingUserFromServer} from '../../core/models/networking-user';
import {EventeeHttpClient} from '../../core/services/http/eventee.http-client';
import {cleanObject} from '../../shared/utils/clean-object';
import {NetworkingV2Store} from './networking-v2.store';
import {NetworkingMatchReaction} from '../networking/networking.service';
import {createCursorPagination, CursorPagination, CursorPaginationFromServer} from '../../core/models/pagination';
import {StorageService} from "../../libraries/storage/storage.service";
import {Message} from "../../core/models/message";
import {NetworkingV2Query} from "./networking-v2.query";

@Injectable({
	providedIn: 'root'
})
export class NetworkingV2Service {

	// its V2 but its V1 API route ... not a bug, leave V1 here 🙅‍
	private suffixV1 = '/v1';
	private suffixEvent = '/event';
	private suffixNetworking = '/networking';

	constructor(
		private networkingStore: NetworkingV2Store,
		private networkingQuery: NetworkingV2Query,
		private http: EventeeHttpClient,
		private storageService: StorageService) {
	}

	joinNetworking(eventId: ID): Observable<any> {
		this.networkingStore.setLoading(true);
		this.networkingStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixEvent}/${eventId}${this.suffixNetworking}`, {}).pipe(
			tap((response) => {
				this.networkingStore.setError(null);
				this.setJoinedNetworking(true);
			}),
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.networkingStore.setLoading(false))
		);
	}

	getFriends(eventId: ID): Observable<NetworkingUser[]> {
		this.networkingStore.setLoading(true);
		this.networkingStore.setError(null);

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixEvent}/${eventId}${this.suffixNetworking}/friends`).pipe(
			map((response: NetworkingUserFromServer[]) => {
				const result = response.map(u => createNetworkingUser(u));
				this.networkingStore.setError(null);
				this.networkingStore.set(result);
				return result;
			}),
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.networkingStore.setLoading(false))
		);
	}

	// Deprecated call
	getUser(eventId: ID, userId: ID): Observable<NetworkingUser> {
		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixEvent}/${eventId}${this.suffixNetworking}/user/${userId}`).pipe(
			map((response: NetworkingUserFromServer) => {
				const result = createNetworkingUser(response);

				if (result.isFriend) {
					this.networkingStore.upsertMany([result]);
				}

				return result;
			}),
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.networkingStore.setLoading(false))
		);
	}

	getUsersToMatch(eventId: ID, limit: number): Observable<{count: number; users: NetworkingUser[]}> {
		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixEvent}/${eventId}${this.suffixNetworking}/users?limit=${limit}`).pipe(
			map((response: {count: number; users: NetworkingUserFromServer[]}) => {
				return {
					...response,
					users: response?.users?.map(u => createNetworkingUser(u))
				};
			}),
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.networkingStore.setLoading(false))
		);
	}

	match(eventId: ID, userId: ID, reaction: NetworkingMatchReaction): Observable<{ matched: boolean }> {
		this.networkingStore.setLoading(true);
		this.networkingStore.setError(null);

		return this.http.post<{matched: boolean}>(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixEvent}/${eventId}${this.suffixNetworking}/match`, {
			user_id: userId, reaction
		}).pipe(
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.networkingStore.setLoading(false))
		);
	}

	getRequests(eventId: ID, force = false, cursor?: string): Observable<CursorPagination<NetworkingUser>> {
		let link = `${environment.appApi.baseUrl}${this.suffixV1}${this.suffixEvent}/${eventId}${this.suffixNetworking}/requests`;

		if (cursor && !force) {
			link = cursor;
		}

		return this.http.get(link, {
			params: {
				items: 30
			}
		}).pipe(
			map((response: CursorPaginationFromServer) => {
				const pagination = createCursorPagination<NetworkingUser>(response, createNetworkingUser);

				if (force) {
					this.networkingStore.updateUI({
						userRequestsTotal: pagination.data.length
					});
				}

				return pagination;
			}),
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.networkingStore.setLoading(false))
		);
	}

	getRejected(eventId: ID, force = false, cursor?: string): Observable<CursorPagination<NetworkingUser>> {
		let link = `${environment.appApi.baseUrl}${this.suffixV1}${this.suffixEvent}/${eventId}${this.suffixNetworking}/rejected`;

		if (cursor && !force) {
			link = cursor;
		}

		return this.http.get(link, {
			params: {
				items: 30
			}
		}).pipe(
			map((response: CursorPaginationFromServer) => {
				return  createCursorPagination<NetworkingUser>(response, createNetworkingUser);
			}),
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.networkingStore.setLoading(false))
		);
	}

	getAllAttendees(eventId: ID, items: number, force = false, query?: string, cursor?: string): Observable<CursorPagination<NetworkingUser>> {
		let link = `${environment.appApi.baseUrl}${this.suffixV1}${this.suffixEvent}/${eventId}${this.suffixNetworking}/all`;

		if (cursor && !force) {
			link = cursor;
		}

		return this.http.get(link, {
			params: cleanObject({
				items,
				search: query
			})
		}).pipe(
			map((response: CursorPaginationFromServer) => {
				return createCursorPagination<NetworkingUser>(response, createNetworkingUser);
			}),
			catchError((error: HttpErrorResponse) => throwError(error)),
			finalize(() => this.networkingStore.setLoading(false))
		);
	}

	updateLastMessage(newMessage: Message) {
		this.networkingStore.update(newMessage.fromUserId, state => ({
			...state,
			lastMessage: newMessage
		}));
	}


	setJoinedNetworking(value: boolean) {
		this.networkingStore.updateUI({
			joined: value
		});
	}

	setActiveNetworkingUser(id: ID) {
		this.networkingStore.setActive(id);
	}

	deductTotalUserRequestsCount() {
		this.networkingStore.updateUI({
			userRequestsTotal: this.networkingStore.getValue().ui.userRequestsTotal - 1
		});
	}
}
