import * as moment from 'moment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {EventsStore} from './events.store';
import {environment} from '../../../environments/environment';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {createEvent, Event, EventFromServer} from '../../core/models/event';
import {Observable, of, throwError} from 'rxjs';
import {ID} from '@datorama/akita';
import {DeviceDetectorService} from 'ngx-device-detector';
import {cleanObject} from '../../shared/utils/clean-object';
import {UsersStore} from '../users/users.store';
import {SessionsStore} from '../sessions/sessions.store';
import {PausesStore} from '../pauses/pauses.store';
import {TracksStore} from '../tracks/tracks.store';
import {NetworkingService} from '../networking/networking.service';
import {createUser, User, UserFromServer, UserRoles} from '../../core/models/user';
import {HallsStore} from '../halls/halls.store';
import {EventsQuery} from "./events.query";
import {LocalStorageKeys} from "../../../global";
import {EventDaysStore} from "../event-days/event-days.store";
import {
	createIntegrationInstagram,
	IntegrationInstagram,
	IntegrationInstagramFromServer
} from '../../core/models/instagram';
import {EventeeHttpClient} from '../../core/services/http/eventee.http-client';
import {NetworkingV2Service} from "../networking-v2/networking-v2.service";
import {FeatureFlags} from "../../libraries/feature-flags/feature-flags";
import {FeatureFlagsService} from "../../libraries/feature-flags/feature-flags.service";

@Injectable({
	providedIn: 'root'
})
export class EventsService {

	private suffixV1 = '/v1';
	private suffixV2 = '/v2';

	constructor(
		private eventsStore: EventsStore,
		private eventDaysStore: EventDaysStore,
		private usersStore: UsersStore,
		private sessionsStore: SessionsStore,
		private pausesStore: PausesStore,
		private tracksStore: TracksStore,
		private eventsQuery: EventsQuery,
		private deviceService: DeviceDetectorService,
		private networkingService: NetworkingService,
		private networkingV2Service: NetworkingV2Service,
		private hallsStore: HallsStore,
		private http: EventeeHttpClient,
		private featureFlagsService: FeatureFlagsService) {
	}

	getOne(eventId: ID): Observable<Event> {
		if (this.eventsQuery.getHasCache() && this.eventsQuery.getActiveId() === eventId) {
			return of(this.eventsQuery.getActive());
		} else {
			this.eventsStore.setHasCache(false);
		}

		this.eventsStore.setLoading(true);
		this.eventsStore.setError(null);
		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/event/${eventId}`).pipe(
			map((response: EventFromServer) => {
				const event = createEvent(response);
				this.eventsStore.setError(null);
				this.eventsStore.upsertMany([event]);
				this.eventsStore.setHasCache(true, {
					restartTTL: true
				});
				return event;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.eventsStore.setLoading(false))
		);
	}

	getByDomain(domain: string): Observable<Event> {
		if (this.eventsQuery.getHasCache() && this.eventsQuery.getActive()?.cnameDomain === domain) {
			return of(this.eventsQuery.getActive());
		} else {
			this.eventsStore.setHasCache(false);
		}

		this.eventsStore.setLoading(true);
		this.eventsStore.setError(null);
		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/event/domain`, {
			params: {
				domain
			}
		}).pipe(
			map((response: EventFromServer) => {
				const event = createEvent(response);
				this.eventsStore.setError(null);
				this.eventsStore.upsertMany([event]);
				return event;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.eventsStore.setLoading(false))
		);
	}

	browse(query?: string) {
		this.eventsStore.setLoading(true);
		this.eventsStore.setError(null);

		const params = new HttpParams()
			.set('query', query);

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV2}/events/browse`, {params}).pipe(
			map((response: EventFromServer[]) => {
				const result = response.map(e => createEvent(e));
				this.eventsStore.setError(null);
				return result;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.eventsStore.setLoading(false))
		);
	}

	checkAccess(eventId: ID, pin?: string) {
		const ui = this.eventsStore.getValue().ui;
		if (ui.joined) {
			return of(this.eventsQuery.getActive());
		} else if (ui.joinError.length > 0) {
			return throwError({
				error: {
					error: ui.joinError
				}
			})
		} else {
			return this.joinEvent(eventId, pin);
		}
	}

	join(eventId: ID, pin?: string) {
		return this.joinEvent(eventId, pin);
	}

	checkPin(eventId: ID, pin?: string) {
		return this.joinEvent(eventId, pin);
	}

	private joinEvent(eventId: ID, pin?: string) {
		this.eventsStore.setLoading(true);
		this.eventsStore.setError(null);

		const device = this.deviceService.device + ' ' + this.deviceService.os + ' ' + this.deviceService.os_version;
		const OS = this.deviceService.browser + ' ' + this.deviceService.browser_version;
		const body = {
			device: device.substring(0, 255),
			OS: OS.substring(0, 255),
			OS_type: 'w',
			pin: null
		};

		if (pin) {
			body.pin = pin + '';
		}

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV2}/event/${eventId}/join`, cleanObject(body)).pipe(
			map((response: {networking: boolean; role: UserRoles}) => {
				this.eventsStore.updateUI({
					joined: true,
					joinError: ''
				});

				// Update users role in joined event
				this.eventsStore.updateActive(active => {
					return {
						...active,
						role: response.role
					};
				});

				this.networkingService.setJoinedNetworking(response.networking);

				if (this.featureFlagsService.featureFlags.networkingV2) {
					this.networkingV2Service.setJoinedNetworking(response.networking);
				}
			}),
			catchError(error => {
				this.eventsStore.updateUI({
					joinError: error.error?.error
				});
				return throwError(error);
			}),
			tap((response: any) => {
				this.eventsStore.setError(null);
			}),
			finalize(() => {
				this.eventsStore.setLoading(false);
			})
		);
	}

	makeAttendee(event: Event) {
		let shouldSend = false;
		let seenBefore = +localStorage.getItem(LocalStorageKeys.SEEN_BEFORE + event.id) ?? 0;
		let seenDuring = +localStorage.getItem(LocalStorageKeys.SEEN_DURING + event.id) ?? 0;
		if (moment().isBefore(event.start)) {
			seenBefore++;
			localStorage.setItem(LocalStorageKeys.SEEN_BEFORE + event.id, seenBefore + '');
		} else if (moment().isBefore(event.end.clone().add(1, 'd'))) {
			seenDuring++;
			localStorage.setItem(LocalStorageKeys.SEEN_DURING + event.id, seenDuring + '');
			shouldSend = true;
		}

		// send only during event and for visitor
		if (shouldSend && event.role === UserRoles.VISITOR) {
			return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/event/${event.id}/attendee`, {
				ahead_of_event: seenBefore,
				during_event: seenDuring
			});
		} else {
			return of({});
		}
	}

	reset() {
		this.eventsStore.reset();
	}

	setActive(id: ID) {
		this.eventsStore.setActive(id);
	}
}
