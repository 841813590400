export interface CursorPaginationFromServer {
	links: {
		prev: string;
		next: string;
	};
	data: [];
	meta: {
		path: string;
		per_page: number;
	};
}

export interface CursorPagination<T> {
	links: {
		prev: string;
		next: string;
	};
	data: T[];
	meta: {
		path: string;
		perPage: number;
	};
}

export function createCursorPagination<T>(params: Partial<CursorPaginationFromServer>, dataConvertFnc: (params: any) => T): CursorPagination<T> {
	return {
		links: params.links,
		data: params.data.map((d) => dataConvertFnc(d)),
		meta: {
			path: params?.meta?.path,
			perPage: params?.meta?.per_page,
		}
	} as CursorPagination<T>;
}
