import {AfterViewInit, Component, Renderer2} from '@angular/core';
import {combineLatest, fromEvent} from 'rxjs';
import {GlobalService} from './core/services/global.service';
import {takeUntil, tap} from 'rxjs/operators';
import {DisposableComponent} from './shared/utils/disposable';
import smoothscroll from 'smoothscroll-polyfill';
import {GoogleService} from './libraries/google/google.service';
import {EventsQuery} from './store/events/events.query';

@Component({
	selector: 'app-root',
	template: `
		<router-outlet></router-outlet>
	`
})
export class AppComponent extends DisposableComponent implements AfterViewInit {
	constructor(
		private renderer2: Renderer2,
		public globalService: GlobalService,
		private googleService: GoogleService,
		private eventsQuery: EventsQuery,
	) {
		super();

		combineLatest([
			fromEvent(window, 'resize')
		]).pipe(
			tap(() => this.resolveWidth()),
			takeUntil(this.componentDestroyed)
		).subscribe();
	}

	ngAfterViewInit() {
		this.resolveWidth();
		smoothscroll.polyfill();
		this.eventsQuery.selectedEvent$.subscribe(event => {
			if (!!event) {
				this.googleService.init(this.renderer2, event);
			} else {
				this.googleService.destroy(this.renderer2);
			}
		});

		// I am sure, this will make a comeback
		// if (localStorage.getItem(LocalStorageKeys.SEEN_APP_MODAL) !== 'true' && device.mobile()) {
		// 	setTimeout(() => {
		// 		this.modalService.show(MobileAppDownloadModalComponent);
		// 	}, 300);
		// }
	}

	resolveWidth() {
		const width = window.innerWidth;

		if (!device?.ipad()) {
			this.globalService.isMobile$.next(width < 700);
		}

		this.globalService.width$.next(width);
	}
}
