export function isColorLight(color) {

	// Variables for red, green, blue values
	// tslint:disable-next-line:one-variable-per-declaration
	let r, g, b, hsp;

	// Check the format of the color, HEX or RGB?
	if (color.match(/^rgb/)) {

		// If HEX --> store the red, green, blue values in separate variables
		color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

		r = color[1];
		g = color[2];
		b = color[3];
	} else {

		// If RGB --> Convert it to HEX: http://gist.github.com/983661
		color = +('0x' + color.slice(1).replace(
			color.length < 5 && /./g, '$&$&'));

		// tslint:disable-next-line:no-bitwise
		r = color >> 16;
		// tslint:disable-next-line:no-bitwise
		g = color >> 8 & 255;
		// tslint:disable-next-line:no-bitwise
		b = color & 255;
	}

	// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
	hsp = Math.sqrt(
		0.299 * (r * r) +
		0.587 * (g * g) +
		0.114 * (b * b)
	);

	// Using the HSP value, determine whether the color is light or dark
	return hsp > 127.5;
}

export function adjustBrightness(col, amt) {

	let usePound = false;

	// tslint:disable-next-line:triple-equals
	if (col[0] == '#') {
		col = col.slice(1);
		usePound = true;
	}

	let R = parseInt(col.substring(0, 2), 16);
	let G = parseInt(col.substring(2, 4), 16);
	let B = parseInt(col.substring(4, 6), 16);

	// to make the colour less bright than the input
	// change the following three "+" symbols to "-"
	R = R + amt;
	G = G + amt;
	B = B + amt;

	if (R > 255) {
		R = 255;
	} else if (R < 0) {
		R = 0;
	}

	if (G > 255) {
		G = 255;
	} else if (G < 0) {
		G = 0;
	}

	if (B > 255) {
		B = 255;
	} else if (B < 0) {
		B = 0;
	}

	// tslint:disable-next-line:triple-equals
	const RR = ((R.toString(16).length == 1) ? '0' + R.toString(16) : R.toString(16));
	// tslint:disable-next-line:triple-equals
	const GG = ((G.toString(16).length == 1) ? '0' + G.toString(16) : G.toString(16));
	// tslint:disable-next-line:triple-equals
	const BB = ((B.toString(16).length == 1) ? '0' + B.toString(16) : B.toString(16));

	return (usePound ? '#' : '') + RR + GG + BB;

}

export function createAlphaHexColor(color: string, opacity: number) {
	return [color.slice(0, 1), 0.75.toString(16), color.slice(0, 1)].join('');
}

export function lightenDarkenColor(col, amt) {

	var usePound = false;

	if (col[0] == "#") {
		col = col.slice(1);
		usePound = true;
	}

	var num = parseInt(col,16);

	var r = (num >> 16) + amt;

	if (r > 255) r = 255;
	else if  (r < 0) r = 0;

	var b = ((num >> 8) & 0x00FF) + amt;

	if (b > 255) b = 255;
	else if  (b < 0) b = 0;

	var g = (num & 0x0000FF) + amt;

	if (g > 255) g = 255;
	else if (g < 0) g = 0;

	return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}

export function hexToRGBA(hexCode, opacity) {
	let hex = hexCode.replace('#', '');

	if (hex.length === 3) {
		hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
	}

	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	return `rgba(${r},${g},${b},${opacity / 100})`;
}
