import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, ID, StoreConfig} from '@datorama/akita';
import {SocialWallPost} from '../../../core/models/social-wall-post';

export interface SocialWallState extends EntityState<SocialWallPost>, ActiveState {
	ui: {
		likes?: Set<ID>;
		dislikes?: Set<ID>;
		userPosts?: Set<ID>;
		socialWallList?: Set<ID>;
		canLoadMore?: boolean;
		hasNewPosts?: boolean;
		shouldShowBadge?: boolean;
	};
}

const initialState = {
	ui: {
		likes: new Set<ID>(),
		dislikes: new Set<ID>(),
		userPosts: new Set<ID>(),
		socialWallList: new Set<ID>(),
		canLoadMore: false,
		hasNewPosts: false,
		shouldShowBadge: false
	}
};

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'social-wall', idKey: 'id', resettable: true})
export class SocialWallStore extends EntityStore<SocialWallState> {

	constructor() {
		super(initialState);
	}

	updateUI(ui: SocialWallState['ui']) {
		this.update(state => ({
			ui: {
				...state.ui,
				...ui
			}
		}));
	}
}

