import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpInterceptor} from '@angular/common/http';
import {InterceptingHandler} from './intercepting.handler';

export const EVENTEE_HTTP_INTERCEPTORS = new InjectionToken<HttpInterceptor[]>(
	'An abstraction on Eventee.co HttpInterceptor[]'
);

@Injectable()
export class EventeeHttpClient extends HttpClient {
	constructor(
		backend: HttpBackend,
		@Inject(HTTP_INTERCEPTORS) interceptors: HttpInterceptor[],
		@Inject(EVENTEE_HTTP_INTERCEPTORS) featureInterceptors: HttpInterceptor[],
	) {
		super(new InterceptingHandler(
			backend,
			[...interceptors, ...featureInterceptors]
		));
	}
}
