import {Injectable} from "@angular/core";
import {QueryEntity} from "@datorama/akita";
import {CdnState, CdnStore} from "./cdn.store";
import {ContentCDNFile} from "../../core/models/content-cdn-file";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class CdnQuery extends QueryEntity<CdnState> {

	getContentCdnFile$(type: string): Observable<ContentCDNFile> {
		return this.selectAll({
			filterBy: contentCdnFile => {
				return contentCdnFile.type === type;
			},
			limitTo: 1
		}).pipe(
			map(files => {
				return files[0];
			})
		);
	}

	constructor(protected store: CdnStore) {
		super(store);
	}
}
