<div class="d-flex w-100 align-items-center justify-content-center flex-column p-5">
    <h6>Feature flags
        <button class="ml-4 btn btn-danger" (click)="featureFlagsService.clearOverrides()">Clear all flag overrides
        </button>
    </h6>
    <div class="mt-4">Keep this open and toggle as you wish, just reload other tabs, where you want changes to be visible</div>

    <!-- Instagram -->
    <div class="d-flex align-items-center p-4 bg-white w-100 border-bottom-gray mt-4">
        <div class="text-dark-gray d-flex align-items-center">
            <i class="mdi mdi-instagram icon-larger"></i>
        </div>
        <div class="d-flex justify-content-center flex-column ml-4">
            <h6>Networking V2</h6>
            <span class="text-dark-gray">
                Shows/Hides Networking V2 tab in menu and uses old floating networking
            </span>
        </div>


		<div class="custom-control custom-checkbox ml-auto">
			<input id="networking-v2" name="answers" type="checkbox" class="custom-control-input"
				   [checked]="featureFlagsService.featureFlags.networkingV2"
				   (change)="featureFlagsService.setOverride('networkingV2', !featureFlagsService.featureFlags.networkingV2)">
			<label for="networking-v2" class="custom-control-label"></label>
		</div>

        <button
                class="ml-4 btn btn-danger"
                [class.invisible]="!featureFlagsService.hasOverride('networkingV2')"
                (click)="featureFlagsService.clearOverrides('networkingV2')">
            <i class="mdi mdi-close"></i>
        </button>
    </div>

    <!-- Partners background color -->
    <div class="d-flex align-items-center p-4 bg-white w-100 border-bottom-gray mt-4">
        <div class="text-dark-gray d-flex align-items-center">
            <i class="mdi mdi-handshake-outline icon-larger"></i>
        </div>
        <div class="d-flex justify-content-center flex-column ml-4">
            <h6>Partners background color</h6>
            <span class="text-dark-gray">
                Accept/Reject partners background color from branding
            </span>
        </div>


		<div class="custom-control custom-checkbox ml-auto">
			<input id="partners-branding-color" name="answers" type="checkbox" class="custom-control-input"
				   [checked]="featureFlagsService.featureFlags.brandingPartnersBackground"
				   (change)="featureFlagsService.setOverride('brandingPartnersBackground', !featureFlagsService.featureFlags.brandingPartnersBackground)">
			<label for="partners-branding-color" class="custom-control-label"></label>
		</div>

        <button
                class="ml-4 btn btn-danger"
                [class.invisible]="!featureFlagsService.hasOverride('brandingPartnersBackground')"
                (click)="featureFlagsService.clearOverrides('brandingPartnersBackground')">
            <i class="mdi mdi-close"></i>
        </button>
    </div>

	<!-- Default schedule view -->
	<div class="d-flex align-items-center p-4 bg-white w-100 border-bottom-gray mt-4">
		<div class="text-dark-gray d-flex align-items-center">
			<i class="mdi mdi-calendar-text-outline icon-larger"></i>
		</div>
		<div class="d-flex justify-content-center flex-column ml-4">
			<h6>Default schedule view</h6>
			<span class="text-dark-gray">
                Sets the default schedule view for attendees.
            </span>
		</div>


		<div class="custom-control custom-checkbox ml-auto">
			<input id="default-schedule-view" name="answers" type="checkbox" class="custom-control-input"
				   [checked]="featureFlagsService.featureFlags.defaultScheduleView"
				   (change)="featureFlagsService.setOverride('defaultScheduleView', !featureFlagsService.featureFlags.defaultScheduleView)">
			<label for="default-schedule-view" class="custom-control-label"></label>
		</div>

		<button
			class="ml-4 btn btn-danger"
			[class.invisible]="!featureFlagsService.hasOverride('defaultScheduleView')"
			(click)="featureFlagsService.clearOverrides('defaultScheduleView')">
			<i class="mdi mdi-close"></i>
		</button>
	</div>
</div>
