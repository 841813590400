import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Session} from '../../core/models/session';
import {Pause} from '../../core/models/pause';

export interface PausesState extends EntityState<Pause>, ActiveState {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'pauses', resettable: true})
export class PausesStore extends EntityStore<PausesState> {

	constructor() {
		super();
	}
}

