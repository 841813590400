import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap, take, tap} from "rxjs/operators";
import {EventsService} from "../../store/events/events.service";
import {EventsQuery} from "../../store/events/events.query";
import {getRouteFromJoinError} from "../../shared/utils/auth-routing";
import {AuthQuery} from "../../store/auth";
import {RouterService} from "../services/router.service";
import {LocalStorageKeys} from "../../../global";

export const eventDetailGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	const eventsService = inject(EventsService);
	const authQuery = inject(AuthQuery);
	const router = inject(RouterService);
	const eventsQuery = inject(EventsQuery);

	const eventId = +eventsQuery.getActiveId();
	const isRedirecting = checkForDynamicLinks(route, router);
	if (isRedirecting) {
		return false;
	}
	return authQuery.isLoggedIn$.pipe(
		take(1),
		switchMap((isLoggedIn) => {
			if (!isLoggedIn) {
				navigateToAuth(router);
				return of(false);
			}
			return eventsService.checkAccess(eventId, route.queryParamMap.get('event_pin')).pipe(
				tap(() => {
					eventsService.makeAttendee(eventsQuery.getActive())
						.pipe(take(1)).subscribe();
				}),
				map(() => true),
				catchError((e) => {
					if (e.error?.error) {
						const routeFromError = getRouteFromJoinError(e.error?.error);
						router.navigate(routeFromError, {replaceUrl: true});
						return of(false);
					}

					return of(false);
				})
			);
		})
	);
};

const navigateToAuth = (router: RouterService) => {
	const url = router.createUrlTree(['auth']);
	window.location.replace(url.toString());
};

const navigateToInvite = (router: RouterService, token: string) => {
	const url = router.createUrlTree(['auth', 'email-register', token]);
	window.location.replace(url.toString());
};
const navigateToSession = (router: RouterService, sessionId: string) => {
	const url = router.createUrlTree(['detail', 'session-detail', sessionId]);
	localStorage.setItem(LocalStorageKeys.REDIRECT_TO, url.toString());
	window.location.replace(url.toString());
};
const checkForDynamicLinks = (route: ActivatedRouteSnapshot, router: RouterService) => {
	const paramLectureId = route.queryParamMap.get('lecture_id');
	const paramToken = route.queryParamMap.get('token');

	if (!!paramLectureId) {
		navigateToSession(router, paramLectureId);
		return true;
	}
	if (!!paramToken) {
		navigateToInvite(router, paramToken);
		return true;
	}
	return false;
};
