import {Injectable} from '@angular/core';
import {SocialWallStore} from './social-wall.store';
import {HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {ID} from '@datorama/akita';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {
	createSocialWallPost,
	createSocialWallPostToServer,
	SocialWallPost,
	SocialWallPostFromServer,
	SocialWallPostInteraction,
	SocialWallPostState
} from '../../../core/models/social-wall-post';
import {EventeeHttpClient} from '../../../core/services/http/eventee.http-client';
import {environment} from '../../../../environments/environment';
import {cleanObject} from '../../../shared/utils/clean-object';
import {StorageService} from '../../../libraries/storage/storage.service';
import {LocalStorageKeys} from '../../../../global';
import {Notification, NotificationType} from '../../../core/models/notification';
import {EventsQuery} from '../../events/events.query';

export enum SocialWallRankAction {
	LIKE = 'l',
	DISLIKE = 'd',
	RESET = 'n'
}

@Injectable({
	providedIn: 'root'
})
export class SocialWallService {

	private suffixV1 = '/v1';
	private suffixEvent = '/event';
	private suffixPosts = '/posts';

	private CHANNELS = {
		INPUT: {
			NOTIFICATION: 'notification'
		}
	};

	constructor(
		private socialWallStore: SocialWallStore,
		private storageService: StorageService,
		private http: EventeeHttpClient,
		private eventsQuery: EventsQuery) {
	}

	getAllApproved(eventId: ID, force: boolean = true, lastId?: ID): Observable<SocialWallPost[]> {
		this.socialWallStore.setError(null);

		if (force) {
			this.socialWallStore.setLoading(true);
			this.socialWallStore.updateUI({
				canLoadMore: true
			});
		}

		const limit = 10;
		let params = new HttpParams().set('items', limit);

		if (lastId) {
			params = params.set('last_id', lastId);
		}

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixEvent}/${eventId}${this.suffixPosts}/approved`, {
			params
		}).pipe(
			map((response: SocialWallPostFromServer[]) => {

				if (force) {
					this.socialWallStore.update(store => ({
						...store,
						ui: {
							...store.ui,
							socialWallList: new Set()
						}
					}));
				}

				const result = response.map(r => createSocialWallPost(r, this.hasSeen(r.id)));
				this.socialWallStore.setError(null);
				this.socialWallStore.upsertMany(result);

				const ids = result.map(r => r.id);

				this.socialWallStore.update(store => ({
					...store,
					ui: {
						...store.ui,
						socialWallList: new Set([...store.ui.socialWallList, ...ids]),
						canLoadMore: response.length === limit,
						hasNewPosts: force ? false : store.ui.hasNewPosts
					}
				}));
				return result;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.socialWallStore.setLoading(false))
		);
	}

	getMyPosts(eventId: ID): Observable<SocialWallPost[]> {
		this.socialWallStore.setError(null);

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/me/${eventId}/posts`).pipe(
			map((response: SocialWallPostFromServer[]) => {
				const result = response.map(r => createSocialWallPost(r, this.hasSeen(r.id)));
				this.socialWallStore.setError(null);
				this.socialWallStore.upsertMany(result);
				this.socialWallStore.updateUI({
					userPosts: new Set<ID>(result.map(r => r.id))
				});
				return result;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.socialWallStore.setLoading(false))
		);
	}

	getMyRankings(eventId: ID): Observable<{ likes: number[]; dislikes: number[] }> {
		this.socialWallStore.setError(null);

		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/me/${eventId}/post/ranks`).pipe(
			tap((response: { likes: number[]; dislikes: number[] }) => {
				this.socialWallStore.setError(null);
				this.socialWallStore.update(store => ({
					...store,
					ui: {
						...store.ui,
						likes: new Set(response.likes),
						dislikes: new Set(response.dislikes),
					}
				}));
				return response;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.socialWallStore.setLoading(false))
		);
	}

	createPost(eventId: ID, post: SocialWallPost): Observable<SocialWallPost> {
		this.socialWallStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}${this.suffixEvent}/${eventId}/post`, {
			...cleanObject(createSocialWallPostToServer(post))
		}).pipe(
			map((response: SocialWallPostFromServer) => {
				const result = createSocialWallPost(response, this.hasSeen(response.id));
				this.socialWallStore.setError(null);
				this.socialWallStore.upsertMany([result]);
				this.socialWallStore.update(store => ({
					...store,
					ui: {
						...store.ui,
						userPosts: new Set([...store.ui.userPosts, result.id])
					}
				}));

				if (result.state !== SocialWallPostState.APPROVED) {
					this.storageService.store(LocalStorageKeys.SEEN_OWN_POSTS, false);
				}
				return result;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			})
		);
	}

	rankSocialWallPost(postId: ID, rank: 'l' | 'd' | 'n'): Observable<SocialWallPost> {
		this.socialWallStore.setError(null);

		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/post/${postId}/rank`, {
			rank
		}).pipe(
			map((response: SocialWallPostFromServer) => {
				const result = createSocialWallPost(response, this.hasSeen(response.id));
				this.socialWallStore.setError(null);
				this.socialWallStore.upsertMany([result]);
				this.socialWallStore.update(store => {

					store.ui.likes.delete(postId);
					store.ui.dislikes.delete(postId);

					if (rank === 'l') {
						store.ui.likes.add(postId);
					} else if (rank === 'd') {
						store.ui.dislikes.add(postId);
					}

					return {
						...store
					};
				});
				return result;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			})
		);
	}

	deleteSocialWallPost(postId: ID): Observable<HttpResponse<object>> {
		return this.http.delete(`${environment.appApi.baseUrl}${this.suffixV1}/post/${postId}/force`,
			{observe: 'response'}).pipe(
			tap(() => {
				this.socialWallStore.remove(postId);
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			})
		);
	}

	interactWithPost(postId: ID, interaction: SocialWallPostInteraction): Observable<HttpResponse<object>> {
		return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/post/${postId}/interact`,
			{interaction}, {observe: 'response'}).pipe(
			tap(() => {
				this.socialWallStore.update(postId, entity => {
					return {
						...entity,
						hasSeen: true
					};
				});
				this.addNewSeen(postId);
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			})
		);
	}

	handleNotification(notification: Notification) {
		if (notification.type === NotificationType.SOCIAL_WALL_POST_CHANGE) {
			this.socialWallStore.update(notification?.data?.id, state => {
				return {
					...state,
					state: notification?.data?.state
				};
			});

			if (notification?.data?.state === SocialWallPostState.APPROVED) {
				this.socialWallStore.updateUI({
					hasNewPosts: true,
					shouldShowBadge: true
				});
			}
		} else if (notification.type === NotificationType.SOCIAL_WALL_POST_DELETED) {
			this.socialWallStore.remove(notification?.data?.id);
		} else if (notification.type === NotificationType.SOCIAL_WALL_POST_RANKED) {
			this.socialWallStore.update(notification?.data?.id, state => {
				return {
					...state,
					likes: notification?.data?.likes,
					dislikes: notification?.data?.dislikes
				};
			});
		}
	}

	dismissNewPosts() {
		this.socialWallStore.updateUI({
			hasNewPosts: false
		});
	}

	hideNewPostsBadge() {
		this.socialWallStore.updateUI({
			shouldShowBadge: false
		});
	}

	removeAllSeenPosts() {
		localStorage.removeItem(LocalStorageKeys.SEEN_SOCIAL_WALL_POST + this.eventsQuery.getActiveId());
	}

	private addNewSeen(swPostId: ID) {
		const key = this.getLocalStorageKey();
		const viewedFeedIds = localStorage.getItem(key)?.split(',') ?? [];
		if (viewedFeedIds.indexOf(swPostId + '') === -1) {
			viewedFeedIds.push(swPostId + '');
			localStorage.setItem(key, viewedFeedIds.join(','));
		}
	}

	private hasSeen(feedId: ID) {
		const key = this.getLocalStorageKey();
		const viewedFeedIds = localStorage.getItem(key)?.split(',') ?? [];
		return viewedFeedIds.indexOf(feedId + '') > -1;
	}

	private getLocalStorageKey() {
		return LocalStorageKeys.SEEN_SOCIAL_WALL_POST + this.eventsQuery.getActiveId();
	}
}
