import {Subject} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';

/**
 * This exists to prevent memory leaks of RxJS subscriptions
 */
@Component({
	selector: 'app-disposable-component',
	template: ''
})
export abstract class DisposableComponent implements OnDestroy {
	public componentDestroyed = new Subject<void>();

	ngOnDestroy(): void {
		this.componentDestroyed.next();
		this.componentDestroyed.complete();
	}
}
