import {EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {ContentCDNFile} from "../../core/models/content-cdn-file";
import {Injectable} from "@angular/core";

export interface CdnState extends EntityState<ContentCDNFile> {
	data: {
		[key:string]: any
	}
}

const initialState = {
	data: {}
};

@Injectable({providedIn: 'root'})
@StoreConfig({
	name: 'cdn',
	resettable: true,
	idKey: 'type'
})
export class CdnStore extends EntityStore<CdnState> {

	constructor() {
		super(initialState);
	}

	updateData(type: string, value: any) {
		this.update(state => {
			return {
				...state,
				data: {
					...state.data,
					[type]: value
				}
			}
		})
	}

}

