import {Injectable, Injector} from '@angular/core';
import {HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {EventsQuery} from '../../store/events/events.query';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {InstagramQuery} from '../../store/socials/instagram/instagram.query';
import {InstagramService} from '../../store/socials/instagram/instagram.service';
import {catchError, filter, last, map, switchMap, take, tap} from 'rxjs/operators';

@Injectable()
export class FacebookRequestInterceptor implements HttpInterceptor {

	private instagramService: InstagramService;
	private eventsQuery: EventsQuery;
	private instagramQuery: InstagramQuery;
	private isRefreshing = false;
	private gettingTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(
		private injector: Injector) {
	}

	addTokenHeaders(request: HttpRequest<any>): HttpRequest<any> {
		let params = request.params ? request.params : new HttpParams();

		if (this.gettingTokenSubject.value) {
			params = params.set('access_token', this.gettingTokenSubject.value);
		}

		return request.clone({
			params
		});
	}

	addMachineIdHeaders(request: HttpRequest<any>): HttpRequest<any> {
		let params = request.params ? request.params : new HttpParams();
		const machineId = this.instagramQuery.getMachineId();

		if (machineId) {
			params = params.set('machine_id', machineId);
		}

		return request.clone({
			params
		});
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		this.instagramService = this.injector.get(InstagramService);
		this.eventsQuery = this.injector.get(EventsQuery);
		this.instagramQuery = this.injector.get(InstagramQuery);

		request = this.addMachineIdHeaders(request);

		if (request.url.includes('oauth/access_token')) {
			return next.handle(request);
		} else {
			return this.getNewAccessToken().pipe(
				// switchMap(() => {
				// 	return this.getLimits(request, next);
				// }),
				switchMap(() => {
					return next.handle(this.addTokenHeaders(request))
				}),
			);
		}
	}

	getLimits(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

		const headReq = request.clone({
			method: 'OPTIONS',
		});

		return next.handle(this.addTokenHeaders(headReq)).pipe(
			last(),
			tap((result: HttpResponse<any>) => {
				if (result.headers.get('x-app-usage')) {
					const limits = JSON.parse(result.headers.get('x-app-usage'));
					return Math.max(limits.call_count, limits.total_cputime, limits.total_time);
				}
				return 0;
			}),
			catchError(error => throwError(error)));
	}

	getNewAccessToken(): Observable<string> {
		if (this.isRefreshing) {
			return this.gettingTokenSubject.pipe(
				filter(token => token != null),
				take(1),
			);
		} else {
			this.isRefreshing = true;
			this.gettingTokenSubject.next(null);

			return this.instagramService.getAccessToken(this.eventsQuery.getActiveId()).pipe(
				map((token) => {
					this.isRefreshing = false;
					this.gettingTokenSubject.next(token);

					return token;
				}),
				catchError(error => throwError(error)));
		}
	}
}
