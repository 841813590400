import {ID} from '@datorama/akita';

export enum CustomStringType {
	ABOUT = 'about',
	JOIN = 'join',
	SCHEDULE = 'schedule',
	SPEAKERS = 'speakers',
	PARTNERS = 'partners',
	SPONSORS = 'sponsors',
	EVENT_INFO = 'event-info',
	CONTACT_INFO = 'contact-info',
	BUY_TICKET = 'buy-ticket',
	SOCIAL_WALL = 'social-wall',
}

export interface CustomString {
	id: ID,
	type: string,
	value: string
}
