import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, finalize, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {ID} from '@datorama/akita';
import {CustomStringsStore} from './custom-strings.store';
import {CustomString, CustomStringType} from '../../core/models/custom-string';
import {CdnService} from "../cdn/cdn.service";
import {ContentCDNType} from "../../core/models/content-cdn-file";
import {environment} from "../../../environments/environment";
import {EventeeHttpClient} from '../../core/services/http/eventee.http-client';

@Injectable({
	providedIn: 'root'
})
export class CustomStringsService {

	private suffixV1 = '/v1';

	constructor(
		private customStringsStore: CustomStringsStore,
		private cdnService: CdnService,
		private http: EventeeHttpClient) {
	}

	getEvents(eventId: ID): Observable<CustomString[]> {
		this.customStringsStore.setLoading(true);
		this.customStringsStore.setError(null);

		return this.cdnService.getFromCDN(
			ContentCDNType.CUSTOM_STRINGS,
			this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/event/${eventId}/strings`)
		).pipe(
			map((response: CustomString[]) => {
				const filteredStrings = this.filterByType(response);
				this.customStringsStore.setError(null);
				this.customStringsStore.upsertMany(filteredStrings);
				return filteredStrings;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.customStringsStore.setLoading(false))
		);
	}

	private filterByType(customStrings: CustomString[]): CustomString[] {
		return customStrings.filter(customString => {
			switch (customString.type) {
				case CustomStringType.ABOUT:
				case CustomStringType.JOIN:
				case CustomStringType.SCHEDULE:
				case CustomStringType.SPEAKERS:
				case CustomStringType.PARTNERS:
				case CustomStringType.SPONSORS:
				case CustomStringType.EVENT_INFO:
				case CustomStringType.CONTACT_INFO:
				case CustomStringType.BUY_TICKET:
					return true;
			}
			return false;
		});
	}
}
