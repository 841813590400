import {ID} from '@datorama/akita';
import {NetworkingConnectionState} from "./networking-user";

export interface UserAuthInfo {
	token?: string;
	userId?: ID;
}

export interface UserAuthInfoFromServer {
	token?: string;
	user_id?: ID;
}

export interface UserFromServer {
	id: number;
	first_name: string;
	last_name: string;
	name: string;
	email: string;
	photo: string;
	phone: string;
	bio: string;
	bio_html: string;
	web: string;
	facebook_link: string;
	twitter_link: string;
	linkedIn_link: string;
	instagram_link: string;
	facebook: string;
	twitter: string;
	linkedIn: string;
	instagram: string;
	position: string;
	company: string;
	country: string;
	language: string;
	secret_number: number;
	last_events: number[];
	email_language: string;
	subscribed: boolean;
	thumbnail: string;
	fb_user: number;
	tw_user: number;
	li_user: number;
	is_anonymous: boolean;
	role: string;
	pivot: {
		role: string;
	};
	pending: boolean;
	imported: boolean;
	used: boolean;
	attendee_id: number;
	session_number: string;
	contact_email: string;
	appsumo: boolean;
	connection_state?: NetworkingConnectionState,
	in_networking?: boolean
}

export interface User {
	id: ID;
	firstName: string;
	lastName: string;
	name: string;
	email: string;
	photo: string;
	phone: string;
	bio: string;
	web: string;
	facebookLink: string;
	twitterLink: string;
	linkedInLink: string;
	facebook: string;
	twitter: string;
	linkedIn: string;
	instagram: string;
	position: string;
	company: string;
	country: string;
	language: string;
	secretNumber: number;
	lastEvents: number[];
	emailLanguage: string;
	subscribed: boolean;
	thumbnail: string;
	fbUser: number;
	twUser: number;
	liUser: number;
	isAnonymous: boolean;
	userType: UserTypes;
	role: UserRoles;
	roleType: UserRoleTypes;
	pending: boolean;
	imported: boolean;
	used: boolean;
	attendeeId: number;
	sessionNumber: string;
	contactEmail: string;
	instagramLink: string;
	appsumo: boolean;
	connectionState?: NetworkingConnectionState;
	inNetworking?: boolean
}

export enum UserTypes {
	ATTENDEE = 'attendee',
	MODERATOR = 'moderator',
	VISITOR = 'visitor',
	ADMIN = 'admin',
	SPEAKER = 'SPEAKER',
}

export enum UserRoles {
	OWNER = 'o',
	ATTENDEE = 'e',
	VISITOR = 'v',
	MODERATOR = 'm',
	ADMIN = 'a'
}

export enum UserRoleTypes {
	OWNER,
	ADMIN,
	ADMIN_PENDING,
	MODERATOR,
	MODERATOR_PENDING,
	ATTENDEE,
	ATTENDEE_PENDING,
	ATTENDEE_IMPORTED,
	VISITOR
}

/**
 * A factory function that creates Events
 */
export function createUser(params: Partial<UserFromServer>) {
	const firstName = params.first_name || '';
	const lastName = params.last_name || '';

	const user = {
		id: params.id,
		firstName,
		lastName,
		name: firstName + ' ' + lastName,
		email: params.email,
		photo: params.photo,
		phone: params.phone,
		bio: params.bio,
		web: params.web,
		facebookLink: removePrefixFromSocialUrl(params.facebook_link),
		twitterLink: removePrefixFromSocialUrl(params.twitter_link),
		linkedInLink: removePrefixFromSocialUrl(params.linkedIn_link),
		instagramLink: removePrefixFromSocialUrl(params.instagram_link),
		instagram: params.instagram || params.instagram_link,
		facebook: params.facebook || params.facebook_link,
		twitter: params.twitter || params.twitter_link,
		linkedIn: params.linkedIn || params.linkedIn_link,
		position: params.position,
		company: params.company,
		country: params.country,
		language: params.language,
		secretNumber: params.secret_number,
		lastEvents: params.last_events,
		emailLanguage: params.email_language,
		subscribed: params.subscribed,
		thumbnail: params.thumbnail,
		fbUser: params.fb_user,
		twUser: params.tw_user,
		liUser: params.li_user,
		isAnonymous: params.is_anonymous,
		used: params.used,
		contactEmail: params.contact_email,
		appsumo: params.appsumo,
		connectionState: params?.connection_state,
		inNetworking: params?.in_networking
	} as User;
	if (user.country?.length === 0) {
		delete user.country;
	}
	return user;
}

/**
 * A factory function that creates Users
 */
export function createUserToServer(params: Partial<User>) {
	return {
		id: params.id,
		first_name: params.firstName,
		last_name: params.lastName,
		name: params.name,
		email: params.email,
		photo: params.photo,
		phone: params.phone,
		bio: params.bio,
		web: params.web,
		facebook_link: !!params.facebookLink ? addPrefixIfNotIncluded('https://facebook.com/', params.facebookLink) : '',
		twitter_link: !!params.twitterLink ? addPrefixIfNotIncluded('https://twitter.com/', params.twitterLink)  : '',
		linkedIn_link: !!params.linkedInLink ? addPrefixIfNotIncluded('https://linkedin.com/in/', params.linkedInLink) : '',
		instagram_link: !!params.instagramLink ? addPrefixIfNotIncluded('https://instagram.com/', params.instagramLink) : '',
		linkedIn: params.linkedIn,
		twitter: params.twitter,
		facebook: params.facebook,
		position: params.position,
		company: params.company,
		country: params.country,
		language: params.language,
		email_language: params.emailLanguage,
		subscribed: params.subscribed,
		thumbnail: params.thumbnail,
		attendee_id: params.attendeeId,
		session_number: params.sessionNumber,
		contact_email: params.contactEmail
	} as UserFromServer;
}

function addPrefixIfNotIncluded(prefix: string, input: string) {
	if (input.includes(prefix)) {
		return input;
	} else {
		return prefix + input;
	}
}

export function createUserAuthInfo(params: Partial<UserAuthInfoFromServer>): UserAuthInfo {
	return {
		token: params.token,
		userId: params.user_id
	} as UserAuthInfo;
}

export function removePrefixFromSocialUrl(url: string) {
	if (url?.includes('/')) {
		if (url?.endsWith('/')) {
			url = url.slice(0, -1);
		}

		return url.substring(url.lastIndexOf('/') + 1, url.length);
	} else {
		return url;
	}
}


export function isAttendee(role: UserRoles) {
	return role === UserRoles.ATTENDEE;
}
