import {Injectable} from '@angular/core';
import {interval, Observable} from 'rxjs';
import {createHttpParams} from '../../shared/utils/http-params';

export interface OAuthConfig {
	clientId: string;
	authorizationEndpoint: string;
	redirectUri: string;
	scope?: string[];
	state?: string;
	width?: number;
	height?: number;
}

@Injectable({
	providedIn: 'root'
})
export class OAuthService {
	constructor() {
	}

	authorize(config: OAuthConfig) {
		const width = config.width ?? 900;
		const height = config.height ?? 480;
		const top = window.screenY + ((window.outerHeight - height) / 2.5);
		const left = window.screenX + ((window.outerWidth - width) / 2);

		return new Observable<string>(subscriber => {
			const oAuth2Window = window.open(this.constructAuthorizationEndpoint(config),
				'Authorize',
				'width=' + width + ',height=' + height + ',top=' + top + ',left=' + left);

			if (oAuth2Window?.focus) {
				oAuth2Window.focus();
			}

			const source$ = interval(500).subscribe(() => {
				if (oAuth2Window.closed) {
					source$.unsubscribe();
					subscriber.error('closed');
				}
				try {
					if (oAuth2Window.document.domain === document.domain) {
						const location = oAuth2Window.location.href;
						const uri = location.split('?')[0];

						const parsedURL = new URL(location);
						const code = parsedURL.searchParams.get('code');
						const error = parsedURL.searchParams.get('error');

						let toClose = false;
						if (error) {
							source$.unsubscribe();
							subscriber.error(error);
							toClose = true;
						} else if (code) {
							source$.unsubscribe();
							oAuth2Window.close();
							subscriber.next(code);
							subscriber.complete();
							toClose = true;
						}
						if (toClose) {
							oAuth2Window.close();
						}
					}
				} catch (e) {
					if (oAuth2Window.closed) {
						source$.unsubscribe();
						subscriber.error('closed');
					}
				}
			});
		});
	}

	private constructAuthorizationEndpoint(config: OAuthConfig): string {
		const params: any = {
			response_type: 'code',
			client_id: config.clientId,
			redirect_uri: config.redirectUri,
			display: 'popup',
			state: config.state
		};
		if (config.scope) {
			params.scope = config.scope.join(',');
		}

		return config.authorizationEndpoint + '?' + createHttpParams(params).toString();
	}
}
