import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, ID, StoreConfig} from '@datorama/akita';
import {NetworkingUser} from '../../core/models/networking-user';
import {User} from '../../core/models/user';

export interface NetworkingV2State extends EntityState<NetworkingUser>, ActiveState {
	ui: {
		joined: boolean;
		userRequestsTotal: number;
	};
}

const initialState = {
	ui: {
		joined: false,
		userRequestsTotal: 0
	}
} as NetworkingV2State;

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'networking-v2', resettable: true})
export class NetworkingV2Store extends EntityStore<NetworkingV2State> {

	constructor() {
		super(initialState);
	}

	updateUI(ui: Partial<NetworkingV2State['ui']>) {
		this.update(state => ({
			ui: {
				...state.ui,
				...ui
			}
		}));
	}
}

