import {environment} from "../../../environments/environment";

export function getMainRoutePath() {
	if (environment.cname) {
		return '';
	} else {
		return 'event/:eventId';
	}
}

export function getSoundPath(filename: string) {
	if (environment.cname) {
		return `../../../app/assets/sounds/${filename}`;
	} else {
		return `../../../assets/sounds/${filename}`;
	}
}
