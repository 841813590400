import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthService} from '../../store/auth';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import * as moment from 'moment-timezone';
import {LocalStorageKeys} from '../../../global';
import {EventsQuery} from '../../store/events/events.query';
import {RouterService} from '../services/router.service';

@Injectable()
export class EventeeRequestInterceptor implements HttpInterceptor {

	private eventsQuery: EventsQuery;
	private authService: AuthService;
	private router: RouterService;
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		private injector: Injector) {
	}

	addEventeeHeaders(request) {
		const authHeader = localStorage.getItem(LocalStorageKeys.TOKEN);
		const language = localStorage.getItem(LocalStorageKeys.LANG) || 'en';
		let headers: any = {
			'Accept-Language': language
		};

		if (authHeader) {
			headers = {
				...headers,
				Authorization: 'Bearer ' + authHeader
			};
		}

		return request.clone({
			setHeaders: headers
		});
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		this.authService = this.injector.get(AuthService);
		this.eventsQuery = this.injector.get(EventsQuery);
		this.router = this.injector.get(RouterService);

		request = this.addEventeeHeaders(request);

		const helper = new JwtHelperService();
		const token = localStorage.getItem(LocalStorageKeys.TOKEN);

		if (!token) {
			return next.handle(request);
		}

		// tslint:disable-next-line:max-line-length
		const isTokenAboutToExpire = moment(helper.getTokenExpirationDate(token)).subtract(30, 'seconds').isBefore(moment());

		// Refresh token before its about to expire (30secs before)
		if (!request.url.includes('/v1/token') && !request.url.includes('.json') && isTokenAboutToExpire) {
			return this.handleTokenRefresh(request, next);
		}

		return next.handle(request).pipe(
			catchError((error) => {
				const removeTokenErrors = ['token_blacklisted', 'token_invalid', 'token_not_provided'];
				if (error instanceof HttpErrorResponse) {
					if ((error.status === 400 || error.status === 401) && removeTokenErrors.includes(error.error?.error)) {
						this.authService.removeToken();
						const eventId = this.eventsQuery.getActiveId();
						if (!!eventId) {
							localStorage.setItem(LocalStorageKeys.REDIRECT_TO, window.location.href);
							this.router.navigate(['auth'], {
								queryParams: {
									security: true,
								}
							});
						}
					}
				}

				return throwError(error);
			})
		);
	}

	private handleTokenRefresh(request: HttpRequest<any>, next: HttpHandler) {
		// If token is not refreshing then:
		// - set state to refreshing
		// - reset observable with refreshToken (refreshTokenSubject)
		// - start refreshing token
		// - if token refresh is successful then send observable with refresh token subject
		// - if something went wrong during refreshing, check if the response is 403 or 401 meaning that you no longer
		// have access and remove the token from storage
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);

			return this.authService.refresh().pipe(
				switchMap((token: any) => {
					this.isRefreshing = false;
					this.refreshTokenSubject.next(token);
					return next.handle(this.addEventeeHeaders(request));
				}),
				catchError(error => {
					if (error instanceof HttpErrorResponse && error.status >= 400) {
						this.authService.removeToken();
					}

					return throwError(error);
				}));
			// Token is already refreshing, so just subscribe to the tokenRefreshSubject and wait for new token to be delivered
		} else {
			return this.refreshTokenSubject.pipe(
				filter(token => token != null),
				take(1),
				switchMap(() => next.handle(this.addEventeeHeaders(request))),
			);
		}
	}
}
