import {Injectable} from '@angular/core';
import {ActivatedRoute, Event, NavigationExtras, Router, RouterState, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {EventsQuery} from '../../store/events/events.query';
import {LocalStorageKeys} from "../../../global";

@Injectable({
	providedIn: 'root'
})
export class RouterService {

	events: Observable<Event>;
	routerState: RouterState;

	constructor(private router: Router,
				private eventsQuery: EventsQuery,
				private route: ActivatedRoute) {
		this.events = router.events;
		this.routerState = router.routerState;
	}

	navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
		if (extras?.relativeTo) {
			// do not change route
			return this.router.navigate(commands, extras);
		} else {
			return this.router.navigate(this.getUrlCommands(commands), extras);
		}
	}

	serializeUrl(url: UrlTree): string {
		return this.router.serializeUrl(url);
	}

	createUrlTree(commands: any[], navigationExtras?: NavigationExtras): UrlTree {
		if (environment.cname) {
			return this.router.createUrlTree(['app', ...this.getUrlCommands(commands)], navigationExtras);
		} else {
			return this.router.createUrlTree(this.getUrlCommands(commands), navigationExtras);
		}
	}

	toNotFound() {
		return this.router.navigate(['404']);
	}
	navigateToDetail() {
		let url = localStorage.getItem(LocalStorageKeys.REDIRECT_TO);

		if (!url?.length) {
			url = this.createUrlTree(['detail'], {queryParamsHandling: 'preserve'}).toString();
		} else {
			localStorage.removeItem(LocalStorageKeys.REDIRECT_TO);
		}

		window.location.replace(url);
	}

	navigateToSpecificSession() {
		const returnUrl = this.route.snapshot.queryParams?.originalUrl;
		window.location.replace(returnUrl);
	}


	private getUrlCommands(commands: any[]): any[] {
		if ((commands.length > 0 && commands[0] === 'event') || environment.cname) {
			// do not change route
			return commands;
		} else {
			const eventId = this.eventsQuery.getActiveId();
			if (!eventId) {
				return ['404'];
			}
			return ['event', eventId, ...commands];
		}
	}
}
