import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {EventsService} from '../../store/events/events.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {resetStores} from '@datorama/akita';
import {LOCALSTORAGE_AGENDA_SCHEDULE_TYPE, setTimeZone} from '../../../global';
import {adjustBrightness} from '../../shared/utils/color-helper';
import {Event, EventStatus, ScheduleType} from '../models/event';
import {EventsStore} from '../../store/events/events.store';
import {HttpErrorResponse} from '@angular/common/http';
import {DOCUMENT} from '@angular/common';
import {Meta, Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import * as moment from 'moment-timezone';
import {CustomStringsService} from '../../store/custom-strings/custom-strings.service';
import {FeatureFlagsService} from '../../libraries/feature-flags/feature-flags.service';
import {StorageService} from '../../libraries/storage/storage.service';
import {inject} from '@angular/core';

export const eventGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
	const router = inject(Router);
	const eventsService = inject(EventsService);
	const eventsStore = inject(EventsStore);
	const featureFlagsService = inject(FeatureFlagsService);
	const customStringsService = inject(CustomStringsService);
	const storageService = inject(StorageService);
	const titleService = inject(Title);
	const metaService = inject(Meta);
	const doc = inject(DOCUMENT);

	let getEventObservable$: Observable<Event>;
	if (environment.cname) {
		getEventObservable$ = eventsService.getByDomain(window.location.hostname);
	} else {
		getEventObservable$ = eventsService.getOne(+route.paramMap.get('eventId'));
	}

	resetStores({exclude: ['auth', 'events']});
	eventsStore.resetUi();
	eventsStore.setHasCache(false);

	return getEventObservable$.pipe(
		switchMap(event => {
			if (event.hasCname && window.location.hostname !== event.cnameDomain && environment.production) {
				window.location.href = 'https://' + event.cnameDomain + '/app/detail';
			}
			eventsService.setActive(event.id);
			if (event.virtual) {
				setTimeZone(moment.tz.guess());
			} else {
				moment.tz.setDefault(event.timezone);
				setTimeZone(event.timezone);
			}
			setMeta(event, titleService, metaService, doc);

			if (event.branding) {
				generateBrandingVariables(event, doc);
			}
			if (!featureFlagsService.hasActiveFlag('brandingPartnersBackground')) {
				doc.documentElement.style.setProperty('--partners-color', '#ffffff');
				doc.documentElement.style.setProperty('--partners-color-contrast', '#000000');
			}

			doc.documentElement.style.setProperty('--preview-height', event.status !== EventStatus.public ? '20px' : '0px');

			featureFlagsService.setGrowthBookAttributes({
				eventId: event.id.toString(),
				companyId: event.company?.id.toString()
			});

			if (featureFlagsService.hasActiveFlag('defaultScheduleView')) {
				storageService.store(LOCALSTORAGE_AGENDA_SCHEDULE_TYPE(event.id),
					event.defaultProgramViewSetting ?? ScheduleType.grid);
			}

			return customStringsService.getEvents(event.id).pipe(map(() => true));
		}),
		catchError((error: HttpErrorResponse) => {
			if (error.status === 404) {
				router.navigate(['404']);
			} else {
				router.navigate(['auth'], {
					queryParams: {
						security: true
					}
				});
			}
			return of(false);
		})
	);
}

const setMeta = (event, titleService, metaService, doc) => {
	titleService.setTitle(event.name);
	metaService.updateTag({name: 'apple-mobile-web-app-title', content: event.name});
	metaService.updateTag({name: 'application-name', content: event.name});
	if (!!event.icon) {
		const icon = doc.querySelector("link[rel*='icon']") as any;
		const appleTouchIcon = doc.querySelector("link[rel*='apple-touch-icon']") as any;
		icon.href = event.icon;
		appleTouchIcon.href = event.icon;
		metaService.updateTag({name: 'msapplication-TileImage', content: event.icon});
	}
	if (event.brandingWeb) {
		metaService.updateTag({name: 'msapplication-TileColor', content: event?.brandingWeb?.activeColor});
		metaService.updateTag({name: 'theme-color', content: event?.brandingWeb?.activeColor});
	}
};


const generateBrandingVariables = (event, doc) => {
	// Creating HEX thats lighter from active color
	document.documentElement.style.setProperty('--active-color-placeholder-alpha', adjustBrightness(event?.brandingWeb?.activeColor, 120));
	document.documentElement.style.setProperty('--active-color-placeholder-darker', adjustBrightness(event?.brandingWeb?.activeColor, -50));

	document.documentElement.style.setProperty('--active-color', event?.brandingWeb?.activeColor);
	document.documentElement.style.setProperty('--active-color-contrast', event?.brandingWeb?.activeContrast);

	document.documentElement.style.setProperty('--background-color', event?.brandingWeb?.backgroundColor);
	document.documentElement.style.setProperty('--background-color-contrast', event?.brandingWeb?.backgroundContrast);

	document.documentElement.style.setProperty('--partners-color', event?.brandingWeb?.partnersColor);
	document.documentElement.style.setProperty('--partners-color-contrast', event?.brandingWeb?.partnersContrast);

	document.documentElement.style.setProperty('--background-color-darker', adjustBrightness(event?.brandingWeb?.backgroundColor, -10));

	if (event?.brandingWeb?.backgroundContrast === '#000000') {
		document.documentElement.style.setProperty('--navbar-color', adjustBrightness(event?.brandingWeb?.backgroundColor, 20));
		document.documentElement.style.setProperty('--background-border', adjustBrightness(event?.brandingWeb?.backgroundColor, -25));
	} else {
		document.documentElement.style.setProperty('--navbar-color', adjustBrightness(event?.brandingWeb?.backgroundColor, -15));
		document.documentElement.style.setProperty('--background-border', adjustBrightness(event?.brandingWeb?.backgroundColor, 20));
	}

	document.documentElement.style.setProperty('--navbar-color-contrast', event?.brandingWeb?.backgroundContrast === '#000000' ? '#000000' : '#ffffff');
};
