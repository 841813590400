import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {EventsService} from "../../store/events/events.service";
import {switchMap, tap} from "rxjs/operators";
import {of} from "rxjs";

@Component({
	selector: 'app-o-auth-redirect',
	templateUrl: './o-auth-redirect.component.html',
	styleUrls: ['./o-auth-redirect.component.scss']
})
export class OAuthRedirectComponent implements OnInit {

	constructor(private activatedRoute: ActivatedRoute, private eventsService: EventsService) {
		let httpParams = new HttpParams();
		this.activatedRoute.queryParams.pipe(
			switchMap(params => {
				if (!params.state) {
					return of(null);
				}
				for (const paramKey in params) {
					if (paramKey !== 'state') {
						httpParams = httpParams.set(paramKey, params[paramKey]);
					}
				}
				return this.eventsService.getOne(+params?.state)
			}),
			tap(event => {
				if (event?.hasCname) {
					window.location.href = 'https://' + event.cnameDomain + '/app/o-auth-redirect/?' + httpParams.toString();
				}
			})
		).subscribe();
	}

	ngOnInit(): void {
	}

}
