import {Image} from './image';
import {createUser, User, UserFromServer} from './user';
import {dateTimeFromServer} from '../../shared/utils/moment';
import {Moment} from 'moment-timezone';
import {ID} from '@datorama/akita';


export enum SocialWallPostInteraction {
	VIEWED = 'v'
}

export enum SocialWallPostState {
	PENDING,
	APPROVED,
	NOT_APPROVED,
	ARCHIVED
}

export interface SocialWallPostFromServer {
	id?: number;
	description?: string;
	media_id?: number;
	image?: Image;
	user_info?: Partial<UserFromServer>;
	likes?: number;
	dislikes?: number;
	viewed?: number;
	approved?: boolean;
	created_at?: string;
	updated_at?: string;
	approved_at?: string;
	deleted_at?: string;
	state?: SocialWallPostState;
}

export interface SocialWallPost {
	id: number;
	description: string;
	mediaId: ID;
	image: Image;
	userInfo: Partial<User>;
	likes: number;
	dislikes: number;
	hasLiked: boolean;
	hasDisliked: boolean;
	viewed: number;
	createdAtTimestamp: number;
	createdAt: Moment;
	deletedAt: Moment;
	updatedAt: Moment;
	approvedAt: Moment;
	approvedAtTimestamp: number;
	hasSeen: boolean;
	state: SocialWallPostState;
}

export function createSocialWallPost(params: Partial<SocialWallPostFromServer>, hasSeen: boolean): SocialWallPost {
	const createdAt = dateTimeFromServer(params.created_at);
	const approvedAt = dateTimeFromServer(params.approved_at);

	return {
		id: params.id,
		description: params.description,
		mediaId: params.media_id,
		image: params.image,
		userInfo: createUser(params.user_info),
		likes: params.likes,
		dislikes: params.dislikes,
		viewed: params.viewed,
		createdAt,
		deletedAt: params.deleted_at ? dateTimeFromServer(params.deleted_at) : null,
		createdAtTimestamp: createdAt.valueOf(),
		approvedAt: params.approved_at ? approvedAt : null,
		approvedAtTimestamp: approvedAt.valueOf(),
		updatedAt: dateTimeFromServer(params.updated_at),
		state: params.state,
		hasSeen,
		hasLiked: false,
		hasDisliked: false
	} as SocialWallPost;
}

export function createSocialWallPostToServer(params: Partial<SocialWallPost>): SocialWallPostFromServer {
	return {
		media_id: params.image?.id,
		description: params.description
	};
}
