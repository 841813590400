import {Injectable, Renderer2} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Event} from "../../core/models/event";
import {environment} from "../../../environments/environment";
import {map, take} from "rxjs/operators";
import {Observable} from "rxjs";

export interface IntegrationGoogleFromServer {
	enabled: boolean;
	analytics_v3_id: string;
	analytics_v4_id: string;
}

export interface IntegrationGoogle {
	enabled: boolean;
	analyticsV3Id: string;
	analyticsV4Id: string;
}

@Injectable({
	providedIn: 'root'
})
export class GoogleService {

	private suffixV1 = '/v1';
	private loaded = false;
	private scriptV3;
	private scriptV3Code;
	private scriptV4;
	private scriptV4Code;

	constructor(private http: HttpClient) {
	}

	init(renderer2: Renderer2, event: Event) {
		if (!this.loaded) {
			this.loaded = true;
			this.getGoogleIntegration(event).subscribe(googleIntegration => {
				if (googleIntegration.enabled) {
					this.insertScripts(renderer2, googleIntegration);
				}
			});
		}
	}

	destroy(renderer2: Renderer2) {
		if (!!this.scriptV3) {
			renderer2.removeChild(document.body, this.scriptV3);
			renderer2.removeChild(document.body, this.scriptV3Code);
		}
		if (!!this.scriptV4) {
			renderer2.removeChild(document.body, this.scriptV4);
			renderer2.removeChild(document.body, this.scriptV4Code);
		}
		this.loaded = false;
	}

	private insertScripts(renderer2: Renderer2, googleIntegration: IntegrationGoogle) {
		if (!!googleIntegration.analyticsV3Id) {
			[this.scriptV3, this.scriptV3Code] = this.insertScript(renderer2, googleIntegration.analyticsV3Id);
		}
		if (!!googleIntegration.analyticsV4Id) {
			[this.scriptV4, this.scriptV4Code] = this.insertScript(renderer2, googleIntegration.analyticsV4Id);
		}
	}

	private insertScript(renderer2: Renderer2, id: string) {
		const scripts = [];

		scripts[0] = renderer2.createElement('script');
		renderer2.setAttribute(scripts[0], 'async', 'true');
		renderer2.setAttribute(scripts[0], 'src', 'https://www.googletagmanager.com/gtag/js?id=' + id);
		renderer2.setAttribute(scripts[0], 'type', 'text/javascript');
		renderer2.appendChild(document.body, scripts[0]);

		scripts[1] = renderer2.createElement('script');
		renderer2.setAttribute(scripts[1], 'type', 'text/javascript');
		scripts[1].text = `window.dataLayer = window.dataLayer || [];
                function gtag() {
                    dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', '${id}');`;
		renderer2.appendChild(document.body, scripts[1]);

		return scripts;
	}

	private getGoogleIntegration(event: Event): Observable<IntegrationGoogle> {
		return this.http.get(`${environment.appApi.baseUrl}${this.suffixV1}/event/${event.id}/integrations/google`)
			.pipe(
				take(1),
				map((integrationGoogleFromServer: IntegrationGoogleFromServer) => {
					return {
						enabled: integrationGoogleFromServer.enabled,
						analyticsV3Id: integrationGoogleFromServer.analytics_v3_id,
						analyticsV4Id: integrationGoogleFromServer.analytics_v4_id,
					}
				})
			);
	}
}
