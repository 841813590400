import {ID} from '@datorama/akita';
import {Moment} from 'moment-timezone';
import {dateTimeFromServerInLocalTimezone} from '../../shared/utils/moment';
import * as moment from 'moment';

export interface InstagramDataPaging<T> {
	data: T[];
	paging: {
		cursors: {
			after: string;
			before: string;
		};
		next: string;
	};
}


export interface InstagramPostFromServer {
	id: number;
	media_url: string;
	username: string;
	timestamp: string;
	caption: string;
	media_type: string;
	permalink: string;
	children: InstagramDataPaging<InstagramPostFromServer>;
}

export enum InstagramPostType {
	CAROUSEL_ALBUM = 'CAROUSEL_ALBUM',
	IMAGE = 'IMAGE',
	VIDEO = 'VIDEO'
}

export interface InstagramPost {
	id: ID;
	mediaUrl: string;
	username: string;
	timestamp: Moment;
	caption: string;
	mediaType: InstagramPostType;
	children: InstagramPost[];
	source: string;
	visible: boolean;
	addedAt: number;
	permalink: string;
}

export function createInstagramPost(params: Partial<InstagramPostFromServer>, source: ID, visible: boolean): InstagramPost {
	return {
		id: params.id,
		mediaUrl: params.media_url,
		username: params.username,
		timestamp: dateTimeFromServerInLocalTimezone(params.timestamp),
		mediaType: params.media_type as InstagramPostType,
		caption: params.caption,
		children: params.children?.data ? params.children?.data?.map(c => createInstagramPost(c, source, visible)) : [],
		source,
		visible,
		permalink: params.permalink,
		addedAt: moment().valueOf()
	} as InstagramPost;
}

export interface InstagramLongLivedResponse {
	access_token: string;
	machine_id: string;
	expires_in: number; // seconds
}

export interface IntegrationInstagramCode {
	code: string;
}

export interface IntegrationInstagramObject {
	id: number;
	name: string;
}

export interface IntegrationInstagramFromServer {
	id: number;
	enabled: boolean;
	instagram_id: string;
	name: string;
	profile_picture: string;
	username: string;
	show_auth_profile: boolean;
	business_users: IntegrationInstagramObject[];
	hashtags: IntegrationInstagramObject[];
	event_id: number;
	authorized: boolean;
}

export interface IntegrationInstagram {
	id: number;
	enabled: boolean;
	instagramId: string;
	name: string;
	profilePicture: string;
	username: string;
	showAuthProfile: boolean;
	businessUsers: IntegrationInstagramObject[];
	hashtags: IntegrationInstagramObject[];
	eventId: number;
	authorized: boolean;
}

export const createIntegrationInstagram = (params: Partial<IntegrationInstagramFromServer>): IntegrationInstagram => ({
	id: params.id,
	enabled: params.enabled,
	instagramId: params.instagram_id,
	name: params.name,
	profilePicture: params.profile_picture,
	username: params.username,
	showAuthProfile: params.show_auth_profile,
	businessUsers: params.business_users,
	hashtags: params.hashtags,
	eventId: params.event_id,
	authorized: params.authorized
} as IntegrationInstagram);
