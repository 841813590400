import {AuthService} from '../../store/auth';
import {FeatureFlagsService} from '../../libraries/feature-flags/feature-flags.service';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {firstValueFrom} from "rxjs";
import Bugsnag from "@bugsnag/js";
import packageInfo from "../../../../package.json";
import {environment} from "../../../environments/environment";

export function eventeeInitializerFactory(authService: AuthService, featureFlagsService: FeatureFlagsService, router: Router) {
	return async () => {
		await FeatureFlagsService.growthBookLoader(featureFlagsService);

		Bugsnag.start({
			appVersion: packageInfo.version,
			releaseStage: environment.bugsnag.stage,
			apiKey: environment.bugsnag.key,
			enabledBreadcrumbTypes: ['error', 'navigation', 'request', 'user'],
			enabledReleaseStages: [ 'production', 'cname', 'staging' ],
		});

		if (localStorage.getItem('token')) {

			try {
				const user = await firstValueFrom(authService.me());

				if (!user.isAnonymous) {
					featureFlagsService.setGrowthBookAttributes({
						userEmail: user.email,
						country: user.country
					});
				}
			} catch (e) {
				try {
					await firstValueFrom(authService.logout());
				} catch (e) {
					authService.removeToken();
					console.error(e);
				} finally {
					await router.navigate(['auth']);
				}
			}
		}
	};
}
