import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, ID, StoreConfig} from '@datorama/akita';
import {Feed} from '../../core/models/feed';
import {SocialWallState} from "../socials/social-wall/social-wall.store";

export interface FeedsState extends EntityState<Feed>, ActiveState {
	ui: {
		shouldShowBadge: boolean;
	};
}

const initialState = {
	ui: {
		shouldShowBadge: false,
	}
};

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'feeds', resettable: true})
export class FeedsStore extends EntityStore<FeedsState> {

	constructor() {
		super(initialState);
	}

	updateUI(ui: FeedsState['ui']) {
		this.update(state => ({
			ui: {
				...state.ui,
				...ui
			}
		}));
	}
}

