import {Injectable} from '@angular/core';
import {HttpContextToken, HttpErrorResponse, HttpParams,} from '@angular/common/http';
import {InstagramStore} from './instagram.store';
import {InstagramQuery} from './instagram.query';
import {
	createInstagramPost,
	createIntegrationInstagram,
	InstagramDataPaging,
	InstagramLongLivedResponse,
	InstagramPost,
	InstagramPostFromServer,
	IntegrationInstagram,
	IntegrationInstagramCode,
	IntegrationInstagramFromServer
} from '../../../core/models/instagram';
import {Observable, of, throwError} from 'rxjs';
import {ID} from '@datorama/akita';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {SocialService} from '../../../libraries/social/social.service';
import * as moment from 'moment-timezone';
import {FacebookHttpClient} from '../../../core/services/http/facebook-http-client.service';
import {EventeeHttpClient} from '../../../core/services/http/eventee.http-client';
import {LocalStorageKeys} from '../../../../global'

export const DO_NOT_USE_EVENTEE_TOKEN = new HttpContextToken<boolean>(() => false);

@Injectable({
	providedIn: 'root'
})
export class InstagramService {

	private suffixV1 = '/v1';

	constructor(
		private instagramStore: InstagramStore,
		private instagramQuery: InstagramQuery,
		private eventeeHttp: EventeeHttpClient,
		private http: FacebookHttpClient) {
	}

	getUserMedia(eventId: ID, userId: ID, force: boolean = true, shouldPostsBeVisible: boolean = true, after?: string): Observable<InstagramPost[]> {
		if (force) {
			this.instagramStore.setIsLoading(userId, true);
			this.instagramStore.remove(store => store.source === userId);
		}

		let params = new HttpParams();

		if (after) {
			params = params.set('after', after);
		}

		params = params.set('fields', 'id,media_url,timestamp,caption,media_type,username,thumbnail_url,permalink,children{id,media_url,media_type}');

		return this.http.get<InstagramDataPaging<InstagramPostFromServer>>(
			`https://graph.facebook.com/${userId}/media`,
			{params}
		).pipe(
			map(response => {
				const posts = response.data.map(instagram => createInstagramPost(instagram, userId, shouldPostsBeVisible));
				this.instagramStore.upsertMany(posts);
				this.instagramStore.update((state) => ({
					...state,
					pagingCursors: {
						...state.pagingCursors,
						[userId]: response?.paging?.cursors?.after,
					},
					canLoadMore: {
						...state.canLoadMore,
						[userId]: !!response?.paging?.cursors?.after
					}
				}));
				return posts;
			}),
			catchError(err => {
				console.error(err);
				return throwError(err);
			}),
			finalize(() => this.instagramStore.setIsLoading(userId, false))
		);
	}

	getHashtagMedia(eventId: ID, hashtagId: ID, force: boolean = true, shouldPostsBeVisible: boolean = true, after?: string): Observable<InstagramPost[]> {
		if (force) {
			this.instagramStore.setIsLoading(hashtagId, true);
			this.instagramStore.remove(store => store.source === hashtagId);
		}

		let params = new HttpParams();

		if (after) {
			params = params.set('after', after);
		}

		const take = 10;

		params = params.set('user_id', this.instagramStore.getValue()?.integration?.instagramId);
		params = params.set('limit', take);
		params = params.set('fields', 'id,media_url,caption,timestamp,media_type,permalink,children{id,media_url,media_type}');

		return this.http.get<InstagramDataPaging<InstagramPostFromServer>>(
			`https://graph.facebook.com/${hashtagId}/top_media`,
			{params}
		).pipe(
			map(response => {
				const result = response.data.map(instagram => createInstagramPost(instagram, hashtagId, shouldPostsBeVisible));

				this.instagramStore.upsertMany(result);
				this.instagramStore.update((state) => ({
					...state,
					pagingCursors: {
						...state.pagingCursors,
						[hashtagId]: response?.paging?.cursors?.after,
					},
					canLoadMore: {
						...state.pagingCursors,
						[hashtagId]: !!response?.paging?.cursors?.after,
					}
				}));
				return result;
			}),
			catchError(err => {
				console.error(err);
				return throwError(err);
			}),
			finalize(() => this.instagramStore.setIsLoading(hashtagId, false))
		);
	}

	getInstagramIntegration(eventId: ID): Observable<IntegrationInstagram> {
		this.instagramStore.setError(null);
		this.instagramStore.setLoading(true);

		const integration = this.instagramStore.getValue()?.integration;

		if (integration) {
			return of(integration);
		} else {
			return this.eventeeHttp.get(`${environment.appApi.baseUrl}${this.suffixV1}/event/${eventId}/integrations/instagram`).pipe(
				map((response: IntegrationInstagramFromServer) => {
					const result = createIntegrationInstagram(response);
					this.instagramStore.setError(null);
					this.instagramStore.update(state => ({
						...state,
						integration: result
					}));
					return result;
				}),
				catchError((error: HttpErrorResponse) => {
					return throwError(error);
				}),
				finalize(() => this.instagramStore.setLoading(false))
			);
		}
	}

	getAccessToken(eventId: ID): Observable<string> {
		const token = this.instagramStore.getValue()?.token;

		if (token) {
			return of(token);
		} else {
			return this.getInstagramClientCode(eventId).pipe(
				switchMap(response => {
					return this.getLongLivedAccessToken(response.code);
				})
			);
		}
	}

	private getInstagramClientCode(eventId: ID): Observable<IntegrationInstagramCode> {
		const params = new HttpParams().set('redirect_uri', environment.socials.redirectUri);

		return this.eventeeHttp.get<IntegrationInstagramCode>(`${environment.appApi.baseUrl}${this.suffixV1}/event/${eventId}/instagram/code`, {
			params
		}).pipe(
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.instagramStore.setLoading(false))
		);
	}

	private getLongLivedAccessToken(code: string): Observable<string> {
		let params = new HttpParams();
		params = params.set('code', code);
		params = params.set('client_id', environment.socials.facebookId);
		params = params.set('redirect_uri', encodeURI(SocialService.redirectUri));

		return this.http.get<InstagramLongLivedResponse>(`https://graph.facebook.com/v14.0/oauth/access_token`, {
			params,
		}).pipe(
			map(longLivedResponse => {
				localStorage.setItem(LocalStorageKeys.INSTAGRAM_MACHINE_ID, longLivedResponse.machine_id);
				this.instagramStore.update(state => ({
					...state,
					token: longLivedResponse.access_token,
					machineId: longLivedResponse.machine_id,
					tokenExpirationDate: moment().add(longLivedResponse.expires_in, 'seconds')
				}));
				return longLivedResponse.access_token;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
			finalize(() => this.instagramStore.setLoading(false))
		);
	}
}
