export function getUUID(): string {
	let uuid = localStorage.getItem('session-number');
	if (!uuid) {
		const uint32p1 = window.crypto.getRandomValues(new Uint32Array(1))[0];
		const uint32p2 = window.crypto.getRandomValues(new Uint32Array(1))[0];
		const uint32p3 = window.crypto.getRandomValues(new Uint32Array(1))[0];
		const uint32p4 = window.crypto.getRandomValues(new Uint32Array(1))[0];
		uuid = uint32p1.toString(16) + '-' + uint32p2.toString(16) + '-' +
			uint32p3.toString(16) + '-' + uint32p4.toString(16);
		localStorage.setItem('session-number', uuid);
	}
	return uuid;
}

export function hasUUID(): boolean {
	return !!localStorage.getItem('session-number');
}
