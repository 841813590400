import {FeatureFlagsService} from './feature-flags.service';

const featureFlagOverride = (defaultValue: any) => {
	return (target: any, memberName: string) => {
		let currentValue: any = target[memberName];

		Object.defineProperty(target, memberName, {
			set: (newValue: any) => {
				const overrides = FeatureFlagsService.getOverrides();

				currentValue = newValue;

				if (overrides && memberName in overrides) {
					currentValue = overrides[memberName]
				}
			},
			get: () => currentValue || defaultValue
		});
	};
};

export enum FeatureFlagType {
	NETWORKING_V2 = 'networking-v2',
	BRANDING_PARTNERS_BACKGROUND = 'branding-partners-background',
	HIDE_MY_AGENDA = 'hide-my-agenda',
	DEFAULT_SCHEDULE_VIEW = 'default-program-view-setting',
	GAMIFICATION = 'gamification',
	RATING_LEVEL = 'rating-level'
}

export class FeatureFlags {

	@featureFlagOverride(false)
	public networkingV2: boolean;

	@featureFlagOverride(false)
	public brandingPartnersBackground: boolean;

	@featureFlagOverride(false)
	public hideMyAgenda: boolean;

	@featureFlagOverride(false)
	public defaultScheduleView: boolean;

	@featureFlagOverride(false)
	public gamification: boolean;

	@featureFlagOverride(false)
	public ratingLevel: boolean;

	constructor() {
	}
}
