import {createUser, User, UserFromServer} from './user';
import {ID} from '@datorama/akita';
import {createMessage, Message, MessageFromServer} from './message';

export interface NetworkingConnection {
	id: ID;
	name: string;
}

export enum NetworkingConnectionState {
	NONE = 0,
	REQUESTED = 1,
	REJECTED = 2,
	MATCHED = 3,
}

export interface NetworkingUserFromServer extends UserFromServer {
	connection: NetworkingConnection;
	last_message: MessageFromServer;
	connection_state: NetworkingConnectionState;
}

export interface NetworkingUser extends User {
	connection: NetworkingConnection;
	lastMessage: Message;
	connectionState: NetworkingConnectionState;
	isFriend: boolean;
}

export function createNetworkingUser(params: Partial<NetworkingUserFromServer>) {
	const lastMessage = (params.last_message && createMessage(params.last_message)) ?? null;

	return {
		...createUser(params as UserFromServer),
		connection: params.connection,
		lastMessage,
		connectionState: params.connection_state,
		isFriend: !!params.email
	} as NetworkingUser;
}
