import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, ID, StoreConfig} from '@datorama/akita';
import {NetworkingUser} from '../../core/models/networking-user';
import {User} from '../../core/models/user';

export interface NetworkingState extends EntityState<NetworkingUser>, ActiveState {
	ui: {
		joined: boolean;
		usersToMatch: Array<User>;
		usersToMatchTotal: number;
		rejectedUsers: Set<ID>;
	};
}

export const initialState = {
	ui: {
		joined: false,
		usersToMatch: [],
		usersToMatchTotal: 0,
		rejectedUsers: new Set<ID>()
	}
} as NetworkingState;

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'networking', resettable: true})
export class NetworkingStore extends EntityStore<NetworkingState> {

	constructor() {
		super(initialState);
	}

	updateUI(ui: Partial<NetworkingState['ui']>) {
		this.update(state => ({
			ui: {
				...state.ui,
				...ui
			}
		}));
	}

	setTotalUsersCount(count) {
		this.update(state => {
			state.ui.usersToMatchTotal = count;

			return state;
		});
	}

	addRejectedUser(user: User) {
		this.update(state => {
			state.ui.rejectedUsers.add(user.id);
			return state;
		});
	}
}

