import {Owner} from './owner';
import * as moment from 'moment-timezone';
import {Company} from './company';
import {ID} from '@datorama/akita';
import {Image} from './image';
import {UserRoles} from './user';
import {ContentCDNFile, ContentCDNFileFromServer, createContentCdnFile} from "./content-cdn-file";

export enum EventStatus {
	hidden,
	rejected ,
	inReview ,
	public
}

export enum EventMembership {
	free = 0,
	trial = 1,
	premium = 2,
	premium_whitelabel = 3,
	enterprise = 4,
	enterprise_whitelabel = 6
}

export interface Payment {
	membership: EventMembership;
	attendees: number;
	created_at: string;
	paid_on?: any;
}


export interface EventAppBrandingFromServer {
	active_color: string;
	active_contrast: string;
	background_color: string;
	background_contrast: string;
	photo_offset: number;
	photo?: Image;
	media_id: number;
}

export interface EventAppBranding {
	activeColor: string;
	activeContrast: string;
	backgroundColor: string;
	backgroundContrast: string;
	photoOffset: number;
	photo: Image;
}

export interface EventWebBrandingFromServer {
	active_color: string;
	active_contrast: string;
	background_color: string;
	background_contrast: string;
	partners_color: string;
	partners_contrast: string;
	photo?: Image;
	media_id: number;
}

export interface EventWebBranding {
	activeColor: string;
	activeContrast: string;
	backgroundColor: string;
	backgroundContrast: string;
	partnersColor: string;
	partnersContrast: string;
	photo: Image;
}

export interface ContactInformation {
	email: string;
	name: string;
	address: string;
	city: string;
	country: string;
	phone: string;
}

export enum ScheduleType {
	grid,
	list
}

export enum RatingLevel {
	on = 'on',
	hide = 'hide',
	off = 'off'
}

export interface EventFromServer {
	id: number;
	name: string;
	annotation: string;
	latitude: number;
	longitude: number;
	timezone: string;
	start: string;
	end: string;
	icon: string;
	photo?: any;
	category: string;
	feeds: boolean;
	networking: boolean;
	testing_code: number;
	status: number;
	number_of_visitors: number;
	created_at: string;
	updated_at: string;
	address: string;
	am_pm_time: boolean;
	tw_account: string;
	tw_hashtags: string[];
	ins_hashtags: string[];
	reviewed_at: string;
	rejected_at: string;
	pin_code: string;
	locked: boolean;
	beacon_uuid: string;
	qr_code: string;
	real_attendees: number;
	rejection_reason: string;
	country_code: string;
	background_color: string;
	social: boolean;
	whitelist: boolean;
	whitelisted: boolean;
	restrict_anonymous: boolean;
	agenda_scale: number;
	status_name: string;
	owner?: Owner;
	hall_count: number;
	links?: any;
	twitter: boolean;
	instagram: boolean;
	payment: Payment;
	company?: Company;
	public_api_token?: any;
	all_feeds_count: number;
	qr_code_url: string;
	ticketing: boolean;
	buy_ticket_url?: any;
	legal_page: string;
	role: string;
	integration_simple_ticket?: any;
	integration_eventbrite?: any;
	slug: string;
	public_event_page: boolean;
	virtual: boolean;
	hide_reviews: boolean;
	hide_my_agenda: boolean;
	default_schedule_view: ScheduleType;
	custom_branding?: EventAppBrandingFromServer;
	custom_web_branding?: EventWebBrandingFromServer;
	cname: { domain: string, is_active: boolean };
	has_tracks: boolean;
	content_cdn_files: ContentCDNFileFromServer[];
	social_wall: boolean;
	social_wall_auto_approve: boolean;
	contact_information: ContactInformation;
	has_gamification: boolean;
	rating_level: RatingLevel;
}

export interface Event {
	id: ID;
	name: string;
	annotation: string;
	latitude: number;
	longitude: number;
	timezone: string;
	start: moment.Moment;
	end: moment.Moment;
	icon: string;
	photo: string;
	category: string;
	feeds: boolean;
	networking: boolean;
	testingCode: number;
	status: number;
	numberOfVisitors: number;
	createdAt: moment.Moment;
	updatedAt: moment.Moment;
	address: string;
	amPmTime: boolean;
	timeFormat: string;
	twAccount: string;
	twHashtags: Set<string>;
	insHashtags: Set<string>;
	reviewedAt: string;
	rejectedAt: string;
	pinCode: string;
	locked: boolean;
	beaconUuid: string;
	qrCode: string;
	realAttendees: number;
	rejectionReason: string;
	countryCode: string;
	backgroundColor: string;
	social: boolean;
	whitelist: boolean;
	whitelisted: boolean;
	restrictAnonymous: boolean;
	agendaScale: number;
	statusName: string;
	owner: Owner;
	hallCount: number;
	links?: [];
	twitter: boolean;
	instagram: boolean;
	payment: Payment;
	company?: Company;
	publicApiToken: string;
	allFeedsCount: number;
	qrCodeUrl: string;
	ticketing: boolean;
	buyTicketUrl?: string;
	legalPageUrl: string;
	role?: UserRoles;
	integrationSimpleTicket?: boolean;
	integrationEventbrite?: boolean;
	slug: string;
	publicEventPage: boolean;
	virtual: boolean;
	hideReviews: boolean;
	hideMyAgenda: boolean;
	defaultProgramViewSetting: ScheduleType;
	branding?: EventAppBranding;
	brandingWeb?: EventWebBranding;
	hasCname: boolean;
	cnameDomain: string;
	hasTracks: boolean;
	contentCdnFiles: ContentCDNFile[];
	socialWall: boolean;
	socialWallAutoApprove: boolean;
	contactInformation: ContactInformation;
	hasGamification: boolean;
	ratingLevel: RatingLevel;
}

/**
 * A factory function that creates Events
 */
export function createEvent(params: Partial<EventFromServer>) {
	return {
		id: params.id,
		name: params.name,
		annotation: params.annotation,
		latitude: params.latitude,
		longitude: params.longitude,
		timezone: params.timezone,
		start: moment.tz(params.start, params.timezone),
		end: moment.tz(params.end, params.timezone),
		icon: params.icon,
		photo: params.photo,
		category: params.category,
		feeds: params.feeds,
		networking: params.networking,
		testingCode: params.testing_code,
		status: params.status,
		numberOfVisitors: params.number_of_visitors,
		createdAt: moment.utc(params.created_at).tz(params.timezone),
		updatedAt: moment.utc(params.updated_at).tz(params.timezone),
		address: params.address,
		amPmTime: params.am_pm_time,
		timeFormat: params.am_pm_time ? 'hh:mm A' : 'HH:mm',
		twAccount: params.tw_account,
		twHashtags: new Set(params.tw_hashtags),
		insHashtags: new Set(params.ins_hashtags),
		reviewedAt: params.reviewed_at,
		rejectedAt: params.rejected_at,
		pinCode: params.pin_code,
		locked: params.locked,
		beaconUuid: params.beacon_uuid,
		qrCode: params.qr_code,
		realAttendees: params.real_attendees,
		rejectionReason: params.rejection_reason,
		countryCode: params.country_code,
		backgroundColor: params.background_color,
		social: params.social,
		whitelist: params.whitelist,
		whitelisted: params.whitelisted || false,
		restrictAnonymous: params.restrict_anonymous || false,
		agendaScale: params.agenda_scale,
		statusName: params.status_name,
		owner: params.owner,
		hallCount: params.hall_count,
		links: params.links,
		twitter: params.twitter,
		instagram: params.instagram,
		payment: params.payment,
		company: params.company,
		publicApiToken: params.public_api_token,
		allFeedsCount: params.all_feeds_count,
		qrCodeUrl: params.qr_code_url,
		ticketing: params.ticketing,
		buyTicketUrl: params.buy_ticket_url,
		legalPageUrl: params.legal_page,
		role: params.role,
		integrationSimpleTicket: params.integration_simple_ticket,
		integrationEventbrite: params.integration_eventbrite,
		slug: params.slug,
		publicEventPage: params.public_event_page,
		virtual: params.virtual,
		hideReviews: params.hide_reviews,
		hideMyAgenda: params.hide_my_agenda,
		defaultProgramViewSetting: params.default_schedule_view,
		branding: params.custom_branding && createEventAppBranding(params.custom_branding),
		brandingWeb: params.custom_web_branding && createEventWebBranding(params.custom_web_branding),
		hasCname: !!params.cname?.is_active,
		cnameDomain: params.cname?.is_active ? params.cname?.domain : '',
		hasTracks: params.has_tracks,
		contentCdnFiles: params.content_cdn_files ? params.content_cdn_files.map(createContentCdnFile) : [],
		socialWall: params.social_wall,
		socialWallAutoApprove: params.social_wall_auto_approve,
		contactInformation: params.contact_information,
		hasGamification: params.has_gamification,
		ratingLevel: params.rating_level
	} as Event;
}

export function getEventStatusClass(event: Event) {
	switch (event.status) {
		case EventStatus.hidden:
			return 'bg-white';
		case EventStatus.rejected:
			return 'bg-danger';
		case EventStatus.inReview:
			return 'bg-warning';
		case EventStatus.public:
			return 'bg-success';
	}
}

export function getEventStatusText(event: Event) {
	switch (event.status) {
		case EventStatus.hidden:
			return 'Hidden';
		case EventStatus.rejected:
			return 'Rejected';
		case EventStatus.inReview:
			return 'In Review';
		case EventStatus.public:
			return 'Public';
	}
}

export function createEventAppBranding(params: Partial<EventAppBrandingFromServer>): EventAppBranding {
	return {
		activeColor: params.active_color,
		activeContrast: params.active_contrast,
		backgroundColor: params.background_color,
		backgroundContrast: params.background_contrast,
		photoOffset: params.photo_offset,
		photo: params.photo,
	} as EventAppBranding;
}

export function createEventWebBranding(params: Partial<EventWebBrandingFromServer>): EventWebBranding {
	return {
		activeColor: params.active_color,
		activeContrast: params.active_contrast,
		backgroundColor: params.background_color,
		backgroundContrast: params.background_contrast,
		partnersColor: params.partners_color,
		partnersContrast: params.partners_contrast,
		photo: params.photo,
	} as EventWebBranding;
}

export function getEventMembership(event: Event) {

	if (event.payment.membership === EventMembership.premium_whitelabel) {
		return EventMembership.premium;
	} else if (event.payment.membership === EventMembership.enterprise_whitelabel) {
		return EventMembership.enterprise;
	}

	return event.payment.membership as EventMembership;
}
