import {ID} from '@datorama/akita';
import * as moment from 'moment-timezone';
import {createEventAppBranding, createEventWebBranding, Event, EventFromServer} from './event';
import {dateTimeFromServer} from '../../shared/utils/moment';
import {NetworkingUser} from './networking-user';
import {Meeting} from './meeting';

export interface MessageFromServer {
	id: ID;
	message: string;
	from_user_id: ID;
	to_user_id: ID;
	created_at: string;
	read_at: string;
}

export interface Message {
	id: ID;
	message?: string;
	fromUserId: ID;
	toUserId: ID;
	createdAt: moment.Moment;
	readAt: moment.Moment;
	isRead: boolean;
	meeting?: Meeting;
}


/**
 * A factory function that creates Events
 */
export function createMessage(params: Partial<MessageFromServer>) {
	return {
		id: params.id,
		message: params?.message,
		createdAt: dateTimeFromServer(params.created_at),
		readAt: params.read_at ? dateTimeFromServer(params.read_at) : null,
		isRead: !!params.read_at,
		fromUserId: params.from_user_id,
		toUserId: params.to_user_id,
	} as Message;
}

export function markMessageAsRead(user: NetworkingUser) {
	let id = user.lastMessage?.id ?? -1;

	localStorage.setItem(`chat_${user.id}_read`, id + '');
}
