import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {EventDay} from '../../core/models/event-day';

export interface DaysState extends EntityState<EventDay>, ActiveState {
}

@Injectable({providedIn: 'root'})
@StoreConfig({
	name: 'event-days',
	resettable: true
})
export class EventDaysStore extends EntityStore<DaysState> {

	constructor() {
		super();
	}
}

