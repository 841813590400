import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {EventsState, EventsStore} from './events.store';
import {Event} from '../../core/models/event';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class EventsQuery extends QueryEntity<EventsState, Event> {

	selectedEvent$: Observable<Event> = this.selectActive();
	joinedEvent$: Observable<boolean> = this.select().pipe(map(state => state.ui.joined));

	constructor(protected store: EventsStore) {
		super(store);
	}
}
