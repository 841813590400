import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {filter, finalize, tap} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class DuplicateCallInterceptor implements HttpInterceptor {

	private activeCalls: Map<string, Subject<any>> = new Map();

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.activeCalls.has(request.url)) {
			const subject = this.activeCalls.get(request.url);
			return subject.asObservable();
		}
		this.activeCalls.set(request.url, new Subject<any>());
		return next.handle(request)
			.pipe(
				filter(res => res.type === HttpEventType.Response),
				tap(res => {
					const subject = this.activeCalls.get(request.url);
					subject.next(res);
					subject.complete();
				}),
				finalize(() => this.activeCalls.delete(request.url))
			);
	}
}
