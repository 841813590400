import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpInterceptor} from '@angular/common/http';
import {InterceptingHandler} from './intercepting.handler';

@Injectable()
export class CDNHttpClient extends HttpClient {
	constructor(
		backend: HttpBackend,
		@Inject(HTTP_INTERCEPTORS) interceptors: HttpInterceptor[]
	) {
		super(new InterceptingHandler(
			backend, interceptors
		));
	}
}
