import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {FeatureFlags, FeatureFlagType} from './feature-flags';
import {GrowthBook} from '@growthbook/growthbook';

export interface GrowthBookEventeeAttributes {
	userEmail?: string;
	country?: string;
	eventId?: string;
	companyId?: string;
}

@Injectable({
	providedIn: 'root',
})
export class FeatureFlagsService {

	static LOCAL_STORAGE_KEY = 'feature-flag-overrides';

	public static instance: FeatureFlagsService;

	public growthBook: GrowthBook = new GrowthBook({
		// enableDevMode: true allows you to use the Chrome DevTools Extension to test/debug.
		enableDevMode: !environment.production,
		attributes: {
			platform: 'admin',
			browser: navigator.userAgent
		}
	});

	public featureFlags: FeatureFlags = new FeatureFlags();

	constructor(
	) {
		FeatureFlagsService.instance = this;
	}

	static async growthBookLoader(featureFlagsService: FeatureFlagsService) {
		try {
			const res = await fetch(environment.growthBook.url);
			const json = await res.json();
			if (environment.production) {
				try {
					await featureFlagsService.growthBook.setEncryptedFeatures(json.encryptedFeatures, environment.growthBook.key);
				} catch (e) {
					featureFlagsService.growthBook.setFeatures(json.features);
				}
			} else {
				featureFlagsService.growthBook.setFeatures(json.features);
			}
			featureFlagsService.evaluateFeatureFlags();
		} catch (e) {
			console.error('Failed to fetch features', e);
		}
	}

	static getOverrides(): Partial<FeatureFlags> | null {
		return JSON.parse(localStorage.getItem(FeatureFlagsService.LOCAL_STORAGE_KEY));
	}

	setGrowthBookAttributes(attributes: GrowthBookEventeeAttributes): void {
		this.growthBook.setAttributes({
			...this.growthBook.getAttributes(),
			...attributes,
			browser: navigator.userAgent,
			platform: 'clientweb'
		});
		this.evaluateFeatureFlags();
	}

	evaluateFeatureFlags(): void {
		this.featureFlags.networkingV2 = this.growthBook.isOn(FeatureFlagType.NETWORKING_V2);
		this.featureFlags.brandingPartnersBackground = this.growthBook.isOn(FeatureFlagType.BRANDING_PARTNERS_BACKGROUND);
		this.featureFlags.hideMyAgenda = this.growthBook.isOn(FeatureFlagType.HIDE_MY_AGENDA);
		this.featureFlags.defaultScheduleView = this.growthBook.isOn(FeatureFlagType.DEFAULT_SCHEDULE_VIEW);
		this.featureFlags.gamification = this.growthBook.isOn(FeatureFlagType.GAMIFICATION);
		this.featureFlags.ratingLevel = this.growthBook.isOn(FeatureFlagType.RATING_LEVEL);
	}

	setOverride(featureFlagName: keyof FeatureFlags, value: any): void {
		const storedFeatureFlags = FeatureFlagsService.getOverrides();
		localStorage.setItem(FeatureFlagsService.LOCAL_STORAGE_KEY, JSON.stringify({
			...storedFeatureFlags,
			[featureFlagName]: value
		} as FeatureFlags));
		this.evaluateFeatureFlags();
	}

	clearOverrides(featureFlagName?: keyof FeatureFlags): void {
		if (featureFlagName) {
			const storedFeatureFlags = FeatureFlagsService.getOverrides();
			delete storedFeatureFlags[featureFlagName];
			localStorage.setItem(FeatureFlagsService.LOCAL_STORAGE_KEY, JSON.stringify(storedFeatureFlags));
		} else {
			localStorage.removeItem(FeatureFlagsService.LOCAL_STORAGE_KEY);
		}

		this.evaluateFeatureFlags();
	}

	hasOverride(featureFlagName?: keyof FeatureFlags): boolean {
		const storedFeatureFlags = FeatureFlagsService.getOverrides();

		if (featureFlagName) {
			return storedFeatureFlags && featureFlagName in storedFeatureFlags;
		} else {
			return !!storedFeatureFlags;
		}
	}

	hasActiveFlag(featureFlagName: keyof FeatureFlags): boolean {
		return this.featureFlags?.[featureFlagName];
	}

	hasActiveFlags(flags: Array<keyof FeatureFlags>): boolean {
		return flags.every(flag => this.hasActiveFlag(flag));
	}
}
