import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Track} from '../../core/models/track';

export interface TracksState extends EntityState<Track>, ActiveState {
	ui: {
		activeIds?: Set<number>
	}
}

const initialState = {
	ui: {
		activeIds: new Set<number>()
	}
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'tracks', resettable: true})
export class TracksStore extends EntityStore<TracksState> {

	constructor() {
		super(initialState);
	}

	updateUI(ui: TracksState['ui']) {
		this.update(state => ({
			ui: {
				...state.ui,
				...ui
			}
		}));
	}

}

