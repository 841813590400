import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {User} from '../../core/models/user';

export interface UsersState extends EntityState<User>, ActiveState {
}

@Injectable({providedIn: 'root'})
@StoreConfig({
	name: 'users',
	resettable: true
})
export class UsersStore extends EntityStore<UsersState> {

	constructor() {
		super();
	}

	updateUI(ui: Partial<UsersState['ui']>) {
		this.update(state => ({
			ui: {
				...state.ui,
				...ui
			}
		}));
	}
}

