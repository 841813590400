import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Event} from '../../core/models/event';
import {environment} from '../../../environments/environment';

export interface EventsState extends EntityState<Event>, ActiveState {
	ui: {
		joined?: boolean,
		joinError?: string
	};
}

const initialState = {
	ui: {
		joined: false,
		joinError: '',
	}
};

@Injectable({providedIn: 'root'})
@StoreConfig({
	name: 'events',
	resettable: true,
	cache: {
		ttl: environment.cacheTTL
	}
})
export class EventsStore extends EntityStore<EventsState> {

	constructor() {
		super(initialState);
	}

	updateUI( ui: EventsState['ui'] ) {
		this.update(state => ({
			ui: {
				...state.ui,
				...ui
			}
		}));
	}

	resetUi() {
		this.updateUI(initialState.ui);
	}

}

